import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatCardTime'
})
export class FormatCardTimePipe implements PipeTransform {
  transform(value: string | number): unknown {

    if(typeof value !== 'number') {
      const numberMatch = value.match(/\d+/);

      if (numberMatch) {
        const number = numberMatch[0];
        const wrappedString = value.replace(number, `<span class="card-site__badge-number">${number}</span>`);
        return wrappedString;
      } else {
        return value;
      }
    } else {
      value = `En <span class="card-site__badge-number">${value}</span> min`;
      return value;
    }
  }
}
