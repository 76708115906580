import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Components } from './components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Services } from '@services';
import { Pipes } from './pipes';
import { Directives } from './directives';
import { TimeStatusPipe } from './pipes/time-status.pipe';
import { FormatCardTimePipe } from './pipes/format-card-time.pipe';
import { ParseUnitOfMesurementPipe } from './pipes/parse-unit-of-mesurement.pipe';
import { ParseQuantityPipe } from './pipes/parse-quantity.pipe';

@NgModule({
  declarations: [...Components, ...Pipes, ...Directives, TimeStatusPipe, FormatCardTimePipe, ParseUnitOfMesurementPipe, ParseQuantityPipe],
  // providers: [...Services],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule.forChild(),
    // NgxTranslateModule
  ],
  exports: [
    ...Components, ...Pipes, ...Directives
  ]
})
export class ShareModule { }
