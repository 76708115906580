export class Constants {

    //LAYOUT
    public static PAGE_NOT_FOUND = 'page.not.found';
    public static PAGE_NOT_FOUND_TITLE = 'page.not.found.title';
    public static ERROR = 'error';
    public static ERROR_TITLE = 'error.title';

    //ROUTES
    public static ROUTE_EDIT = 'edit';
    public static ROUTE_CREATE = 'create';
    public static ROUTE_ERROR = '/error';

    //REDUX ACTIONS
    public static ACTION_ORDERS_FETCH = '[Orders] Fetch Orders';
    public static ACTION_ORDERS_FORMAT = '[Orders] Format Orders';
    public static ACTION_ORDERS_CHECK_STATUS = '[Orders] Check Status Orders';
    public static ACTION_ORDERS_SET = '[Orders] Set Orders';
    public static ACTION_ORDERS_UPDATE = '[Orders] Update Order';
    public static ACTION_ORDERS_STATUS_UPDATE = '[Orders] Update Order Status';
    public static ACTION_ORDERS_FLOW_UPDATE = '[Orders] Update Order Flow';
    public static ACTION_ORDERS_PHOTO_UPDATE = '[Orders] Update Order Photo';
    public static ACTION_ORDERS_BATCH_UPDATE = '[Orders] Update Batch';
    public static ACTION_ORDERS_BATCH_ADD_ITEM = '[Orders] Add Batch Item';
    public static ACTION_ORDERS_ITEM_CHECK_UPDATE = '[Orders] Update Item Check';
    public static ACTION_ORDERS_TIME_STATE_UPDATE = '[Orders] Update Time State';
    public static ACTION_ORDERS_DELETE = '[Orders] Delete Order';
    public static ACTION_ORDERS_CREATE = '[Orders] Create Order';
    public static ACTION_ORDERS_CLEAN = '[Orders] Clean Orders';
    public static ACTION_ORDERS_REMOVE = '[Orders] Remove Orders';
    public static ACTION_ORDERS_ADD_CANCELLED_ORDER = '[Orders] Add Cancelled Order';
    public static ACTION_ORDERS_ERROR = '[Orders] Error';
    
    public static ACTION_USER_LOGIN = '[User] Login User';
    public static ACTION_USER_SET = '[User] Set User';
    public static ACTION_USER_REMOVE = '[User] Remove User';
    public static ACTION_USER_UPDATE_SITE = '[User] Update User Site';
    public static ACTION_USER_SET_SITE = '[User] Set User Site';
    public static ACTION_USER_ERROR = '[User] Error';
    
    public static ACTION_SITES_FETCH = '[Sites] Sites Fetch';
    public static ACTION_SITES_SET = '[Sites] Set Sites';
    public static ACTION_SITES_SELECT = '[Sites] Select Sites';
    public static ACTION_SITES_CLEAN = '[Sites] Clean Sites';

    public static ACTION_STATION_BRANDS_FETCH = '[Station] Fetch Station Brands';
    public static ACTION_STATION_BRANDS_UPDATE = '[Station] Update Station Brands';
    public static ACTION_STATION_BRANDS_ERROR = '[Station] Error';
    
    public static ACTION_STATS_FETCH = '[Stats] Fetch Stats';
    public static ACTION_STATS_SET = '[Stats] Set Stats';
    public static ACTION_STATS_CLEAN = '[Stats] Clean Stats';
    public static ACTION_STATS_SET_TOTAL_ORDERS = '[Stats] Set Total Orders';
    public static ACTION_STATS_SET_TOTAL_RIDERS = '[Stats] Set Total Riders';
    public static ACTION_STATS_ADD_DELAY_ORDER = '[Stats] Add Delay Order';
    public static ACTION_STATS_REMOVE_DELAY_ORDER = '[Stats] Remove Delay Order';
    public static ACTION_STATS_CHECK_DELAY_ORDERS = '[Stats] Check Delay Order';

    public static ACTION_PEOPLE_FETCH = '[People] Fetch People';
    public static ACTION_PEOPLE_SET = '[People] Set People';
    public static ACTION_PEOPLE_SELECT = '[People] Select People';
    public static ACTION_PEOPLE_CLEAN = '[People] Clean People';
    public static ACTION_RESPONSIBLE_SELECTED = '[Responsible] Set Responsible';
    public static ACTION_RESPONSIBLE_UPDATE = '[Responsible] Update Responsible';

    public static ACTION_CONFIG_FETCH = '[Config] Fetch Config';
    public static ACTION_CONFIG_SET = '[Config] Set Config';
    public static ACTION_CONFIG_CLEAN = '[Config] Clean Config';
    public static ACTION_CONFIG_ERROR = '[Config] Error';

    //ORDER CARDS
    public static WARNING_STATUS_TIME: number = 180; //seconds

    public static ORDER_STATUS_SUCCESS: string = 'success';
    public static ORDER_STATUS_WARNING: string = 'warning';
    public static ORDER_STATUS_ALERT: string = 'alert';
    public static ORDER_STATUS_EMPTY_FLOW: string = 'empty-flow';

    public static STATION_TYPE_COOK: string = 'cook';
    public static STATION_TYPE_PREP: string = 'prep';
    public static STATION_TYPE_PACK: string = 'pack';
    public static STATION_TYPE_RUNNER: string = 'runner';
    public static STATION_TYPE_SITE: string = 'site';
    public static STATION_TYPE_LITE: string = 'lite';
    public static STATION_TYPE_HIGH_DEMAND: string = 'high_demand';

    public static FLOW_START: string = 'started_at';
    public static FLOW_FINISH: string = 'finished_at';

    //ROLES TYPES
    public static ROLE_TYPE_KITCHEN: string = 'kitchen';
    public static ROLE_TYPE_OBSERVER: string = 'observer';
    public static ROLE_TYPE_SUPERADMIN: string = 'superadmin';

    //QR PICKER
    public static ALLOWED_CHARS = /^([0-9a-zA-Z-])$/
    public static MAX_LAST_RESULTS = 5
    public static THRESOLD_TIME: number = 500

    //ORDERS ALERTS
    public static DELAYED_START_TIME_ALERT: number = 30000;
    
    //POLLINGS
    public static POLLING_SERVER_TIME: number = 600000;
    // public static POLLING_OCS: number = 60000;
    public static POLLING_OCS: number = 120000;
    public static POLLING_USER_TIME: number = 1800000; //30 minutes
    // public static POLLING_USER_TIME: number = 10000; //30 minutes

    //PEOPLE SELECT TIME LIMIT (SECONDS)
    public static EVENTS_TIMER: number = 1800;
    // public static EVENTS_TIMER: number = 15;

}