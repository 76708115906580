import { createReducer, on } from '@ngrx/store';
import * as actions from './config.actions';
import { ConfigData } from '@models/config.model';


export const initialState: ConfigData = { id: '', sitting: '', camera_station: [], free_pack: false, calculator: false};

export const configReducer = createReducer(initialState,
  on( actions.setConfig, (state, { config }) => setConfig(state, config)),
  on( actions.cleanConfig, (state) => cleanConfig())
);

function setConfig(state: ConfigData, config: ConfigData) {

  if(!config) return state;
  let newCofig: ConfigData = { ...config };
  if(state.sitting && newCofig.sitting !== state.sitting) localStorage.removeItem('cancelledOrders');
  return newCofig;
}

function cleanConfig(){
  return initialState;
}

