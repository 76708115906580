import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProxyService } from './proxy.service';

@Injectable({
  providedIn: 'root'
})
export class WarehouseItemsService extends ProxyService {
  constructor(override http: HttpClient) {
    super(http);
  }

  getStockBreaks(site_id: number | string = null): Observable<any> {
    let url: string = `${environment.api_inventory_url}warehouse_items_stock_breaks`
    const tokenValue: string = `Bearer ${localStorage.getItem('token')}`;
    const headers = new HttpHeaders({
      'authorization': tokenValue
    });
    const requestOptions = { headers: headers };

    if(site_id){
      let advanced_filter = {};
      advanced_filter = {...advanced_filter, site_id: [site_id]};

      url += `?advanced_filter=${JSON.stringify(advanced_filter)}`;
    }

    return this.http.get(url, requestOptions)
  }
}
