import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { OrderCardData, OrderLine } from '@models/orderCardData.model';
import { StateCard } from '@models/stateCard.model';
import { OrdersStateService, UtilsService } from '@services';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-order-list-cook',
  templateUrl: './order-list-cook.component.html',
  styleUrls: ['./order-list-cook.component.scss'],
})
export class OrderListCookComponent {
  @Input() order: any;
  @Input() dropDownActive: string;
  @Input() updateCard: boolean;
  @Output() dropDownState: EventEmitter<any> = new EventEmitter();
  @Output() clickedOrder: EventEmitter<{order: OrderCardData, action: string}> = new EventEmitter();
  @Output() orderTimeState: EventEmitter<{order: OrderCardData, timeState: number}> = new EventEmitter();

  lastMilliseconds: string;
  dataCard: StateCard = {};
  
  constructor(
    private ordersStateService: OrdersStateService,
    public translate: TranslateService,
    public utilsService: UtilsService
  ) { }

  ngOnInit(){
    this.orderState();
  }

  ngOnChanges(changes:SimpleChanges) {
    if(this.checkOrderToRefresh(changes) || !!changes.updateCard){     
      this.orderState();
    } 
  }

  checkDisableButton(){
    return (this.utilsService.netDisabled$.value || this.utilsService.waitingFirestoreResponse$.value.find(orderWaiting => orderWaiting === this.order.id));
  }

  trackByItems(item: any ): string {
    return item
  }

  checkOrderToRefresh(changes: SimpleChanges){
    if(!changes.order || !changes.order.previousValue || !changes.order.currentValue) return false;
    const result = _.pickBy(changes.order.currentValue, (v, k) => !_.isEqual(changes.order.previousValue[k], v));
    return !_.isEmpty(result);
  }

  clickInOrder(){
    if(!!this.order.dates.cancelled_at) {
      this.ordersStateService.clickCancelledOrder(this.order);
      return;
    }
    this.clickedOrder.emit({order: this.order, action: this.dataCard.flowStatus});
  }

  toggleDropdown() {
    this.ordersStateService.logOrder(this.order);
    this.dropDownState.emit(this.order.id);
  }

  setStrike(item: OrderLine){      
    if(!this.order.is_started) return;
    item.is_striked = !item.is_striked;
    return item.is_striked ? 'line-through' : '';
  }

  checkStrike(item: OrderLine){
    return item.is_striked;
  }

  orderState(){
    this.dataCard.classHeader = '';
    this.dataCard.isGrid = false;
    this.dataCard = this.ordersStateService.checkOrderState(this.order, this.dataCard, this.translate.instant('card.finish'));
  }
}
