import { createAction, props } from '@ngrx/store';
import { Constants } from '@share/constants';
import { UserData } from '@models';


export const loginUser = createAction(
    Constants.ACTION_USER_LOGIN,
    props<{ token: string, reload: boolean }>()
);

export const setUser = createAction(
    Constants.ACTION_USER_SET,
    props<{ user: any, reload: boolean }>()
);

export const removeUser = createAction(
    Constants.ACTION_USER_REMOVE,
    // props<{ token: string }>()
);

export const updateUserSite = createAction(
    Constants.ACTION_USER_UPDATE_SITE,
    props<{ user: any }>()
);

export const setUserSite = createAction(
    Constants.ACTION_USER_SET_SITE,
    props<{ user: UserData }>()
);

export const errorLoadingData = createAction(
    Constants.ACTION_ORDERS_ERROR
);
