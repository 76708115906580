import { createReducer, on } from '@ngrx/store';
import { FirebaseStats, OrderCardData, StatsData } from '@models';
import * as actions from './stats.actions';

export const initialState: StatsData = { firebaseStats: null, riders: 0, delayOrders: [], totalOrders: 0};

export const statsReducer = createReducer(initialState,
  on( actions.setStats, (state, { stats }) => setStats(state, stats)),
  on( actions.setTotalOrders, (state, { orders }) => setTotalOrders(state, orders)),
  on( actions.setTotalRiders, (state, { riders }) => setTotalRiders(state, riders)),
  on( actions.addDelayOrder, (state, { orderId }) => addDelayOrder(state, orderId)),
  on( actions.removeDelayOrder, (state, { orderId }) => removeDelayOrder(state, orderId)),
  on( actions.checkDelayOrders, (state, { orders }) => checkDelayOrders(state, orders)),
  on( actions.cleanStats, (state) => cleanStats())
);

function setStats(state: StatsData, stats: FirebaseStats) {

  if(!stats) return state;
  const StatsData: FirebaseStats = JSON.parse(JSON.stringify({ ...stats }));
  return {...state, firebaseStats: StatsData};
}

function setTotalOrders(state: StatsData, orders: number) {
  return {...state, totalOrders: orders};
}

function setTotalRiders(state: StatsData, riders: number) {
  return {...state, riders: riders};
}

function addDelayOrder(state: StatsData, orderId: string) {
  let settedDelayOrders: string[] = [...state.delayOrders, orderId];
  return {...state, delayOrders: settedDelayOrders};
}

function removeDelayOrder(state: StatsData, orderId: string) {
  let settedDelayOrders: string[] = [...state.delayOrders.filter(delayOrder => delayOrder !== orderId)];
  return {...state, delayOrders: settedDelayOrders};
}

function checkDelayOrders(state: StatsData, orders: OrderCardData[]) {
  let settedDelayOrders: string[] = [];

  orders.forEach(order =>{
    state.delayOrders.forEach(delayOrder =>{
      if(delayOrder === order.id) settedDelayOrders.push(delayOrder);
    })
  })

  return {...state, delayOrders: settedDelayOrders};
}

function cleanStats(){
  return initialState;
}

