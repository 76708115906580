import { createReducer, on } from '@ngrx/store';
import { BrandModel, UserData } from '@models';
import * as actions from './user.actions';


export const initialState: UserData = null;

export const userReducer = createReducer(initialState,
  on( actions.setUser, (state, { user }) => setUserData(user)),

  on( actions.removeUser, () => {
    localStorage.clear();
    return null;
  }),

  on( actions.updateUserSite, (state, { user }) => setUserSites(state, user)),
  on( actions.errorLoadingData, () => {
    localStorage.clear();
    return null;
  })
);

function setUserData(user: UserData) {
    let parseUser: any = JSON.parse(JSON.stringify({ ...user }));
    let settedUser: any = {};

    settedUser.id = parseUser.id;
    settedUser.low_demand = parseUser.low_demand;
    settedUser.created_at = parseUser.created_at;
    settedUser.description = parseUser.description;
    settedUser.email = parseUser.email;
    settedUser.is_active = parseUser.is_active;
    settedUser.name = parseUser.name;
    // settedUser.type = (settedUser.low_demand && (!parseUser.role || parseUser.role === 'kitchen')) ? 'lite' : parseUser.type;
    settedUser.type = parseUser.type;
    settedUser.tags = parseUser.tags;
    settedUser.site = {
      id: parseUser.site.id,
      is_360: parseUser.site.is_360,
      name: parseUser.site.name, 
      short_name: parseUser.site.short_name, 
    };

    settedUser.role = parseUser.role;
    settedUser.stationRestaurants = [];
    settedUser.siteRestaurants = [];

    parseUser.restaurants.forEach( restaurant => {
      let restaurantData: BrandModel = {
        external_id: restaurant.external_id,
        name: restaurant.brand.name,
        is_active: restaurant.is_active,
        created_at: restaurant.created_at
      }
      settedUser.stationRestaurants.push(restaurantData);
    })

    parseUser.site.restaurants.forEach( restaurant => {
      let restaurantData: BrandModel = {
        external_id: restaurant.external_id,
        name: restaurant.brand.name,
        is_active: restaurant.is_active,
        created_at: restaurant.created_at
      }
      settedUser.siteRestaurants.push(restaurantData);
    })

    localStorage.setItem('user', JSON.stringify(settedUser));
    localStorage.setItem('userServer', JSON.stringify(parseUser));
    return settedUser as UserData;
}

function setUserSites(state: UserData, impersonateUser: any) {  
  
  if(!impersonateUser) return state;
  let parseUser: any = JSON.parse(JSON.stringify({ ...state }));
  parseUser.siteRestaurants = [];
  parseUser.stationRestaurants = [];

  parseUser.id = impersonateUser.id;

  parseUser.site = {
    id: impersonateUser.site.id,
    is_360: false,
    name: impersonateUser.site.name, 
    short_name: impersonateUser.site.short_name, 
  };

  impersonateUser.restaurants.forEach( restaurant => {
    let restaurantData: BrandModel = {
      external_id: restaurant.external_id,
      name: restaurant.brand.name,
      is_active: restaurant.is_active,
      created_at: restaurant.created_at
    }
    parseUser.stationRestaurants.push(restaurantData);
  })

  impersonateUser.site.restaurants.forEach( restaurant => {
    let restaurantData: BrandModel = {
      external_id: restaurant.external_id,
      name: restaurant.brand.name,
      is_active: restaurant.is_active,
      created_at: restaurant.created_at
    }
    parseUser.siteRestaurants.push(restaurantData);
  })

  localStorage.setItem('user', JSON.stringify(parseUser));

  return parseUser as UserData;
}

  

