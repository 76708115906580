import { Component, Input, Output, OnInit,OnDestroy, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { OrderCardData, OrderLine } from '@models/orderCardData.model';
import { StateCard } from '@models/stateCard.model';
import { OrdersStateService } from '@services';
import { Constants } from '@share/constants';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-order-runner',
  templateUrl: './order-runner.component.html',
  styleUrls: ['./order-runner.component.scss'],
})
export class OrderRunnerComponent implements OnInit,OnDestroy {
  @ViewChild('orderCard') card: ElementRef;
  @Input() orderOriginal: OrderCardData;
  @Input() orderCardSet: OrderCardData;
  @Input() header = true;
  @Input() footer = true;
  @Output() clickedOrder: EventEmitter<{order: OrderCardData; action: string}> = new EventEmitter();
  @Output() orderTimeState: EventEmitter<{order: OrderCardData; timeState: number}> = new EventEmitter();
  setHeaderStatus: any;
  milliseconds: string;
  timeUpdate: any;
  dataCard: StateCard = {};
  ctaDisabled = false;
 
  constructor(
    private orderStateService: OrdersStateService,
    public translate: TranslateService
  ){}


  get platformName(){
    let classBase = 'platform__';

    switch (this.orderOriginal?.platform?.name.toLowerCase()) {
      case 'glovo':
        classBase += 'glovo';
        break;
      case 'uber':
        classBase += 'uber-eats';
        break;
      case 'flipdish':
        classBase += 'flipdish';
        break;
      case 'justeat':
        classBase += 'just-eat';
        break;
      default:
        classBase += 'stuart';
        break;
    }

    return classBase;
  }

  ngOnInit(){
    if(this.timeUpdate) {clearInterval(this.timeUpdate);}
    this.orderState();
    this.setHeaderStatus = setInterval(() => {
      this.orderState();
    }, 1000);
  }

  ngOnDestroy() {
    if(this.setHeaderStatus) {clearInterval(this.setHeaderStatus);}
    if(this.timeUpdate) {clearInterval(this.timeUpdate);}
  }

  clickInOrder() {
    this.clickedOrder.emit({order: this.orderOriginal, action: this.dataCard.flowStatus});
  }

  setStrike(item: OrderLine) {
    item.is_striked = !item.is_striked;
    return item.is_striked ? 'line-through' : '';
  }

  checkStrike(item: OrderLine){
    return item.is_striked;
  }

  orderState() {
    this.dataCard.classHeader = '';
    this.dataCard.isGrid = true;
    if (!this.header) {this.dataCard.classHeader += 'card--break-top';}
    if (!this.footer) {this.dataCard.classHeader += ' card--break-bottom';}

    switch (this.orderOriginal.status) {
      case 0:
        this.dataCard.classHeader = 'card--accepted';
        this.dataCard.ctaSecondaryText = this.translate.instant('card.postpone');
        this.dataCard.ctaText = this.translate.instant('card.prior');
        this.dataCard.isLoading = false;
        this.dataCard.setPriority = true;
        break;
      case 1:
        this.dataCard.classBtn = 'button--primary button--disabled button--icon button--loading';
        this.dataCard.classHeader = 'card--preparation';
        this.dataCard.ctaText = this.translate.instant('card.cooking');
        this.dataCard.isLoading = true;
        this.dataCard.setPriority = false;
        break;
      case 2:
        this.dataCard.classBtn = 'button--primary';
        this.dataCard.classHeader = this.orderCardSet.rider_here ? 'card--ready-and-rider' : 'card--ready';
        this.dataCard.ctaText = this.translate.instant('card.deliver');
        this.dataCard.isLoading = false;
        this.dataCard.setPriority = false;
        break;
    }

    const nowMilliseconds: number = moment().utc().valueOf();
    const difference: number = (this.orderOriginal?.finish_time_estimated - nowMilliseconds)/1000;

    const now = moment().utc().format();
    const diffDates = moment(now).diff(moment(this.orderOriginal.dates.created_at));
    this.dataCard.acceptedTime = moment(diffDates).format('mm:ss');

    this.dataCard.classHeader += ' card';

    switch (true) {
      case this.dataCard.startTime && !this.orderOriginal.is_started:
        break;
      case difference > Constants.WARNING_STATUS_TIME:
        this.dataCard.classHeader += '--active-success';
        break;
      case difference > 0 && difference < Constants.WARNING_STATUS_TIME:
       this.dataCard.classHeader += '--active-warning';
        break;
      default:
        this.orderStateService.checkDelayOrder(this.orderOriginal.id);
        this.dataCard.classHeader += '--active-alert';
        break;
    }
  }

  logOrder(){
    this.orderStateService.logOrder(this.orderOriginal);
  }
}
