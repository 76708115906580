import { Injectable } from '@angular/core';
import { Observable, Subscription, from, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from '@share/constants';
import { AppState } from 'src/app/core/app.reducer';
import { Store } from '@ngrx/store';
import * as stationRestaurantsActions from '../../features/station-brands/store/station-brands.actions';
import { map, switchMap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class StationBrandsService {

  openStoresStatusTimer$: Subscription;

  constructor(private http: HttpClient, private store: Store<AppState>, public afAuth: AngularFireAuth) {}

  getCurrentSite(){
    let siteName: string;

    this.store.select(state => state.sites?.siteSelected?.id).subscribe(id => {
      if(id) siteName = id.toUpperCase();
    });

    return siteName;
    
  }

  getStationBrandsStatus(site_short_name: string): Observable<any>{
    const url: string = `${environment.api_ocs_url}stores/${site_short_name}`;
    const tokenValue: string = `Bearer ${localStorage.getItem('token')}`;
    const headers = new HttpHeaders({
      'authorization': tokenValue
    });
    const requestOptions = { headers: headers };
    return this.http.get(url, requestOptions) as Observable<any>;
  }

  pollingStationBrands() {
    if(this.openStoresStatusTimer$ ) this.openStoresStatusTimer$.unsubscribe();
    this.openStoresStatusTimer$ = timer(0, Constants.POLLING_OCS).subscribe({
      next: () => {
        this.getStationBrands();
      }
    });
  }

  getStationBrands() {
    const user = JSON.parse(localStorage.getItem('user'));
    const site = (user.role !== 'kitchen') ? this.getCurrentSite() : user.site.short_name;
    if(!site) return;
    let getOpenStoresStatus$: Subscription = this.getStationBrandsStatus(site).pipe(
      map((data: any) => data.filter((brand: any) => user.stationRestaurants.some( restaurant => restaurant.external_id === brand.id))),
    )
      .subscribe({ 
        next: (brandsOcs: any)=> {
          this.store.dispatch(stationRestaurantsActions.updateStationBrands({brands: brandsOcs}));
        },
        error: (error)=>{
          console.log("printRes error", error);
          if(error.status == 0 || error.status == 401){
            this.setToken().subscribe(token => {
              localStorage.setItem('token', token);
              this.getStationBrands();
            });
          }
          getOpenStoresStatus$.unsubscribe();
        },
        complete: () =>{
          getOpenStoresStatus$.unsubscribe();
        }
      })

  }

  public setToken(): any {
    return from(this.afAuth.currentUser).pipe(switchMap((user: any) => {
      return from(user.getIdTokenResult(true)).pipe(map((res: any) => {        
        return res.token;
      }));
    }));
  }
}
