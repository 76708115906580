import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeStatus'
})
export class TimeStatusPipe implements PipeTransform {
  transform(time: string, previous: string = ''): string {    
  if (time && previous) {
      return Math.round(moment.duration(moment(time).diff(previous)).asMinutes()) + ' min';
    } else if (time) {
      return moment(time).format('H:mm')+ ' h';
    } else {
      return '';
    }
  }
}
