import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { FirestoreService } from '@services';

import * as actions from './stats.actions';


 
@Injectable()
export class StatsEffects {
  
  setStats$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.fetchStats),
      switchMap((data) => 
        this.firestoreService.getStats(data.user, data.config).pipe(
          map((stats) => actions.setStats({stats})),
          catchError((err) => {
            console.log("error", err);
            return of(actions.errorLoadingData());
          })
        )
      )
    ) 
  });

  constructor(
    private actions$: Actions,
    private firestoreService: FirestoreService
  ) {}
}