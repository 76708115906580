import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppState } from '../../../core/app.reducer';
import { FirebaseSite, SitesData } from '@models/sites.model';
import * as sitesActions from '../../../features/sites/store/sites.actions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sites-select',
  templateUrl: './sites-select.component.html',
  styleUrls: ['./sites-select.component.scss'],
})
export class SitesSelectComponent implements OnInit {

  sites$: Observable<SitesData> = this.store.select(state => state.sites);

  constructor(
    private store: Store<AppState>,
    private modalController: ModalController,
  ) { }

  ngOnInit() {}

  selectSite(item: FirebaseSite){
    // const user = JSON.parse(localStorage.getItem('user')!);
    this.store.dispatch(sitesActions.selectSite({ site: item }));
    
    
    // const settedPeople: Subscription = this.userService.setPeopleSelected(item, user.site.id, user.id)
    // .subscribe({
    //   next: () => {
    //     settedPeople.unsubscribe();
    //   },
    //   error: (err) => {
    //     settedPeople.unsubscribe();
    //   },
    //   complete: () => {
    //     settedPeople.unsubscribe();
    //   }
    // });

    this.modalController.dismiss(item);
  }

}
