import { Component, OnInit } from '@angular/core';
import { FirebasePeople, PeopleData } from '@models/people.model';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../../core/app.reducer';
import * as peopleActions from '../../../features/people/store/people.actions';
import { ModalController } from '@ionic/angular';
import { UserService } from '@services';

@Component({
  selector: 'app-people-select',
  templateUrl: './people-select.component.html',
  styleUrls: ['./people-select.component.scss'],
})
export class PeopleSelectComponent implements OnInit {

  people$: Observable<PeopleData> = this.store.select(state => state.people);

  constructor(
    private store: Store<AppState>,
    private modalController: ModalController,
    private userService: UserService
  ) { }


  ngOnInit() {}

  selectPeople(item: FirebasePeople){
    const user = JSON.parse(localStorage.getItem('user')!);
    this.store.dispatch(peopleActions.updateResponsible({ station_id: user.id, responsible: item }));
    
    
    this.userService.setPeopleSelected(item);

    this.modalController.dismiss(item);
  }

}
