import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { from, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import { Store } from '@ngrx/store';
import { AppState } from '../../core/app.reducer';
import * as configActions from './../../features/config/store/config.actions';
import * as ordersActions from './../../features/orders/store/orders.actions';
import * as peopleActions from './../../features/people/store/people.actions';
import * as siteActions from './../../features/sites/store/sites.actions';
import * as statsActions from './../../features/stats/store/stats.actions';
import * as userActions from './../../features/user/store/user.actions';
import { NavController } from '@ionic/angular';
import { Order } from '@models/orderFirebase.model';
import { UtilsService } from './utils.service';
import { FirebasePeople } from '@models/people.model';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  auth = getAuth();

  constructor(
    private http: HttpClient,
    private navCtrl: NavController,
    public afAuth: AngularFireAuth,
    private utilsService: UtilsService,
    private store: Store<AppState>
  ) {}


  public setToken(firebaseOrder?: Order): any {
    return from(this.afAuth.currentUser).pipe(switchMap((user: any) => {
      return from(user.getIdTokenResult(true)).pipe(map((res: any) => {        
        return res.token;
      }));
    }));
  }

  // Auth logic to run auth providers
  authLogin(email: string, password: string) {
    this.utilsService.showLoader();
    signInWithEmailAndPassword(this.auth, email, password)
    .then(() => {
        this.authState();
    })
    .catch((error) => {
      this.utilsService.dismissLoader();
      // TODO: Show alert error
      this.utilsService.showLoginAlert('Usuario o contraseña incorrecto');
      console.log(error);
    });
    
  }

  authState(): void {
    const authState$: Subscription = this.afAuth.authState.pipe(
      distinctUntilChanged((prev: any, curr: any) => {
        let xorWithResult = _.xorWith(prev, curr, _.isEqual);
        let emptyResult = _.isEmpty(xorWithResult);

        return emptyResult;
      })
    )
    .subscribe(
      
      {
        next: (userData:any) => {
          if (userData) {
            const token: string = userData.multiFactor.user.accessToken;
            localStorage.setItem('token', token);
            
            this.store.dispatch(userActions.loginUser({token: token, reload: false}));
          } else {
            this.store.dispatch(userActions.removeUser());
          }
        },
        error: (err) => {
          this.utilsService.dismissLoader();
          this.utilsService.showLoginAlert('Usuario o contraseña incorrecto');
          this.store.dispatch(userActions.removeUser());
          authState$.unsubscribe();
        },
        complete: () => {
          authState$.unsubscribe();
        }
      });
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== null && user.emailVerified !== false ? true : false;
  }

  printOrder(order: Order): any {
    const url: string = `${environment.api_sites_url}sites/stations/print`;
    const tokenValue: string = `Bearer ${localStorage.getItem('token')}`;
    const headers = new HttpHeaders({
      'authorization': tokenValue
    });
    const requestOptions = { headers: headers };
    this.http.post(url, order, requestOptions).subscribe( 
      printRes =>{ console.log("printRes", printRes);},
      error =>{ 
        console.log("printRes error", error);
        if(error.status == 0 || error.status == 401){
          this.setToken().subscribe(token => {
            localStorage.setItem('token', token);
            if(order) this.printOrder(order);
          });
        }
      })
  }

  loginUser(accessToken: string): Observable<any> {
    const url: string = `${environment.api_sites_url}sites/stations/login`;
    const tokenValue: string = `Bearer ${accessToken}`;
    const headers = new HttpHeaders({
      'authorization': tokenValue
    });
    const requestOptions = { headers: headers };
    return this.http.post(url, null, requestOptions);
  }

  updateUserSite(siteSelected: string):any {
    const url: string = `${environment.api_sites_url}sites/stations/impersonate_login`;
    const data = { short_name: siteSelected };
    const tokenValue: string = `Bearer ${localStorage.getItem('token')}`;
    const headers = new HttpHeaders({
      'authorization': tokenValue
    });
    const requestOptions = { headers: headers };
    const impersonateUser$: Subscription = this.http.post(url, data, requestOptions)
    .subscribe({
      next: (user: any) => {
        this.store.dispatch(userActions.updateUserSite({user: user}));
      },
      error: (err) => {
        impersonateUser$.unsubscribe();
        if(err.status == 0 || err.status == 401){
          const updateToken$: Subscription = this.setToken()
          .subscribe({
            next: (token) => {
              localStorage.setItem('token', token);
              updateToken$.unsubscribe();
              this.updateUserSite(siteSelected);
            },
            error: (err) => {
              updateToken$.unsubscribe();
            },
            complete: () => {
              updateToken$.unsubscribe();
            }
          })
        }
      },
      complete: () => {
        impersonateUser$.unsubscribe();
      }
    })
  }

  setPeopleSelected(peopleSelected: FirebasePeople): any {
    const url: string = `${environment.api_sites_url}sites/stations/cook_info`;
    const data = { cook_id: peopleSelected.employeeId.toString(), cook_name: peopleSelected.name };
    const tokenValue: string = `Bearer ${localStorage.getItem('token')}`;
    const headers = new HttpHeaders({
      'authorization': tokenValue
    });
    const requestOptions = { headers: headers };
    const peopleSelected$: Subscription = this.http.patch(url, data, requestOptions)
    .subscribe({
      next: (peopleSelectedRes) => {
        console.log("peopleSelectedRes", peopleSelectedRes);
      },
      error: (err) => {
        peopleSelected$.unsubscribe();
        if(err.status == 0 || err.status == 401){
          const updateToken$: Subscription = this.setToken()
          .subscribe({
            next: (token) => {
              localStorage.setItem('token', token);
              updateToken$.unsubscribe();
              this.setPeopleSelected(peopleSelected);
            },
            error: (err) => {
              updateToken$.unsubscribe();
            },
            complete: () => {
              updateToken$.unsubscribe();
            }
          })
        }
      },
      complete: () => {
        peopleSelected$.unsubscribe();
      }
    })
  }

  signOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.utilsService.initTime$.next(null);
    this.utilsService.serverTime$.next(null);
    if(this.utilsService.eventsTimer$) this.utilsService.eventsTimer$.unsubscribe();
    if(this.utilsService.peopleTimer$) this.utilsService.peopleTimer$.unsubscribe();
    if(this.utilsService.openStoresStatusTimer$) this.utilsService.openStoresStatusTimer$.unsubscribe();
    this.store.dispatch(statsActions.cleanStats());
    this.store.dispatch(peopleActions.cleanPeople());
    this.store.dispatch(configActions.cleanConfig());
    // this.store.dispatch(ordersActions.cleanOrders());
    this.store.dispatch(ordersActions.removeOrders());
    this.store.dispatch(userActions.removeUser());
    this.store.dispatch(siteActions.cleanSite());
    this.navCtrl.navigateRoot('/login');
    // this.afAuth.signOut().then(() => {
    // });
  }

  reloadApp(){
    let setToken$: Subscription =  this.setToken().subscribe(() =>{
      this.store.dispatch(userActions.loginUser({token: localStorage.getItem('token'), reload: true}));
      setToken$.unsubscribe();
    })
  }
}
