import { DateClockPipe } from "./date-clock.pipe";
import { DateOrderLimitPipe } from "./date-order-limit.pipe";
import { DateStartedPipe } from "./date-started.pipe";
import { ParseAmountPipe } from "./parse-amount.pipe";
import { TimeStatusPipe } from "./time-status.pipe";

export const Pipes: any[] = [
    DateClockPipe,
    DateOrderLimitPipe,
    DateStartedPipe,
    ParseAmountPipe,
    TimeStatusPipe
];