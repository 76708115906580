import { createReducer, on } from '@ngrx/store';
import { FirebasePeople, PeopleData } from '@models';
import * as actions from './people.actions';

export const initialState: PeopleData = { firebasePeople: [], responsibleSelected: null };

export const peopleReducer = createReducer(initialState,
  on( actions.setPeople, (state, { people }) => setPeople(state, people)),
  on( actions.selectPeople, (state, { responsible }) => setResponsible(state, responsible)),
  on( actions.cleanPeople, (state) => cleanPeople())
);

function setPeople(state: PeopleData, people: FirebasePeople[]) {

  if(!people) return state;
  const PeopleData: FirebasePeople[] = JSON.parse(JSON.stringify([...people ]));
  return {...state, firebasePeople: PeopleData};
}

function setResponsible(state: PeopleData, responsible: FirebasePeople) {

  if(!responsible) return state;
  const ResponsibleSelected: FirebasePeople = JSON.parse(JSON.stringify({...responsible }));
  return {...state, responsibleSelected: ResponsibleSelected};
}

function cleanPeople(){
  return initialState;
}