import { createAction, props } from '@ngrx/store';
import { Constants } from '@share/constants';
import { ConfigData, FirebaseStats, OrderCardData, StatsData, UserData } from '@models';


export const fetchStats = createAction(
    Constants.ACTION_STATS_FETCH,
    props<{ user: UserData, config: ConfigData }>()
);

export const setStats = createAction(
    Constants.ACTION_STATS_SET,
    props<{ stats: FirebaseStats }>()
);

export const setTotalOrders = createAction(
    Constants.ACTION_STATS_SET_TOTAL_ORDERS,
    props<{ orders: number }>()
);

export const setTotalRiders = createAction(
    Constants.ACTION_STATS_SET_TOTAL_RIDERS,
    props<{ riders: number }>()
);

export const addDelayOrder = createAction(
    Constants.ACTION_STATS_ADD_DELAY_ORDER,
    props<{ orderId: string }>()
);

export const removeDelayOrder = createAction(
    Constants.ACTION_STATS_REMOVE_DELAY_ORDER,
    props<{ orderId: string }>()
);

export const checkDelayOrders = createAction(
    Constants.ACTION_STATS_CHECK_DELAY_ORDERS,
    props<{ orders: OrderCardData[] }>()
);

export const cleanStats = createAction(
    Constants.ACTION_STATS_CLEAN
);

export const errorLoadingData = createAction(
    Constants.ACTION_ORDERS_ERROR
);
