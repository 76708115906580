import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './core/app.reducer';
import * as userActions from './features/user/store/user.actions';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';
import { UserService, UtilsService } from '@services';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  netEnabled: boolean = true;

  constructor(
    private store: Store<AppState>,
    public translate: TranslateService,
    private userService: UserService,
    private utilsService: UtilsService,
    private platform: Platform
  ) {
    if(localStorage.getItem('userServer')){
      let userData = JSON.parse(localStorage.getItem('userServer'));
      this.store.dispatch(userActions.setUser({user: userData, reload: false}));
      this.userService.authState();
    }

    if(this.platform.ready()){
      window.addEventListener("load", () => {
        window.addEventListener("online", () => {
          this.netEnabled = true;
          this.utilsService.netDisabled$.next(false);
        })
        
        window.addEventListener("offline", () => {
          this.netEnabled = false;
          this.utilsService.netDisabled$.next(true);
        })

        window.addEventListener("click", () => {
          this.utilsService.eventsTimer = 0;
        })
      })
    }

  }
}