import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { OrderCardData } from '@models/orderCardData.model';
import { OrdersStateService, UserService, UtilsService } from '@services';
import { Constants } from '@share/constants';
import * as moment from 'moment';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { UserData } from '@models/userData.model';

@Component({
  selector: 'app-order-site',
  templateUrl: './order-site.component.html',
  styleUrls: ['./order-site.component.scss'],
})
export class OrderSiteComponent {

  @Input() order: OrderCardData;
  @Input() dropDownActive: string;
  @Input() updateCard: boolean;
  @Input() user: UserData = null;
  @Output() dropDownState: EventEmitter<any> = new EventEmitter();
  @Output() clickedOrder: EventEmitter<{order: OrderCardData, action: string}> = new EventEmitter();
  @Output() orderTimeState: EventEmitter<{order: OrderCardData, timeState: number}> = new EventEmitter();

  ctaText: string;
  classBtn: string;
  classHeader: string;
  flowStatus: string;

  startTime: string;
  statusTime: string;
  riderTime: string;
  riderMinutes: any;
  dispatchTime: string;

  constructor(
    private ordersStateService: OrdersStateService,
    private userService: UserService,
    public translate: TranslateService,
    public utilsService: UtilsService
  ) { }

  ngOnChanges(changes:SimpleChanges) {
    if(this.checkOrderToRefresh(changes) || !!changes.updateCard){ 
      this.checkOrderStatus();
    } 
  }

  checkDisableButton(){
    return ((this.utilsService.netDisabled$.value && this.order?.status <= 1) 
      || this.utilsService.waitingFirestoreResponse$.value.find(orderWaiting => orderWaiting === this.order.id) 
      || this.user.role === 'observer' 
      // || (this.order.site_is_360 && !(this.order?.status === 2 || (this.order?.status != 9 && this.order?.dates?.cancelled_at)))
    );
  }

  checkOrderToRefresh(changes: SimpleChanges){
    if(!changes.order || !changes.order.previousValue) return false;
    const result = _.pickBy(changes.order.currentValue, (v, k) => !_.isEqual(changes.order.previousValue[k], v))
      return !_.isEmpty(result);
  }

  clickInOrder(){
    if(!!this.order.dates.cancelled_at) {
      this.ordersStateService.clickCancelledOrder(this.order);
      return;
    }

    if(this.order.status === 2 && this.order.rider_here) {
      this.emitChangeInOrder();
    } else if(this.order.status === 2 && !this.order.rider_here) {
      this.utilsService.showAlert('Cuidado, ¿Estás seguro?', 'El Rider aún no ha escaneado el QR.', ['Cancelar', 'OK']).then(res =>{
        if(res.role === 'ok'){
          this.emitChangeInOrder();
        }
      });
    } else {
      this.utilsService.showAlert('¿Estás seguro?', 'Este cambio de estado afectará a cocina.', ['Cancelar', 'OK']).then(res =>{
        if(res.role === 'ok'){
          this.emitChangeInOrder();
        }
      });
    }
  }

  emitChangeInOrder(){
    this.ctaText = 'Enviando...';
    this.clickedOrder.emit({order: this.order, action: this.flowStatus});
  }

  toggleDropdown(e: any) {
    this.ordersStateService.logOrder(this.order);
    if(e.target?.dataset?.name === 'buttonEvent') {
      e.preventDefault();
      e.stopPropagation();
    }else{
      this.dropDownState.emit(this.order.id);  
    }
  }

  checkOrderStatus(){
    this.classHeader = '';

    const milliseconds = moment().utc().valueOf();

    this.riderMinutes = this.checkRiderStimation(milliseconds);

    this.riderTime = this.ordersStateService.checkRiderTime(this.order);

    let time = moment(milliseconds).diff(moment(this.order.dates.created_at));
    this.startTime = moment(this.order.dates.created_at).locale(moment.locale('es')).fromNow(true);

    switch (true) {
      case this.order.flow_empty:
        this.statusTime = '00:00';
        this.ctaText = this.translate.instant('card.flow_error');
        this.classBtn = 'button--outline';
        this.classHeader += ' card-site--empty-flow';
        return;

      case !!this.order.dates.cancelled_at:
        this.startTime = '--';
        this.riderTime = '--';
        this.statusTime = (this.order.status !== 9 || this.order.dates.cancelled_at && this.order.status !== 9) ? this.translate.instant('card.cancelled').toUpperCase() : (moment(this.order.dates.cancelled_at).format('HH:mm'));
        this.ctaText = (this.order.status !== 9) ? this.translate.instant('card.close') : this.translate.instant('card.cancelled');
        this.classBtn = 'button--outline';
        this.classHeader += (this.order.status !== 9) ? ' card-site--cancel' : ' card-site--cancelled';
        return;

      case this.order.status === 0:
        this.ctaText = (this.utilsService.waitingFirestoreResponse$.value.find(orderWaiting => orderWaiting === this.order.id)) ? 'Enviando...' : this.translate.instant('card.accepted');
        this.flowStatus = Constants.FLOW_START;
        this.classBtn = 'button--outline';
        time = moment(milliseconds).diff(moment(this.order.dates.created_at));
        this.statusTime = (time >= 0) ? ((moment.duration(time).hours() * 60) + moment.duration(time).minutes()).toString() : undefined;
        this.classHeader = 'card-site--preparation';
        return;

      case this.order.status === 1:
        this.ctaText = (this.utilsService.waitingFirestoreResponse$.value.find(orderWaiting => orderWaiting === this.order.id)) ? 'Enviando...' : this.translate.instant('card.preparing');
        this.flowStatus = Constants.FLOW_FINISH;
        this.classBtn = 'button--outline';
        time = moment(milliseconds).diff(moment(this.order.dates.created_at));
        this.statusTime = (time >= 0) ? ((moment.duration(time).hours() * 60) + moment.duration(time).minutes()).toString() : undefined;
        this.classHeader = 'card-site--preparation';
        return;
      case this.order.status === 2:
        this.ctaText = (this.utilsService.waitingFirestoreResponse$.value.find(orderWaiting => orderWaiting === this.order.id)) ? 'Enviando...' : this.translate.instant('card.ready');
        this.classBtn = 'button--primary';
        time = moment(milliseconds).diff(moment(this.order.dates.timestamp_2 || this.order.dates.created_at));
        this.statusTime = (time >= 0) ? ((moment.duration(time).hours() * 60) + moment.duration(time).minutes()).toString() : undefined;
        this.classHeader = this.order.rider_here ? 'card-site--ready-and-rider' : 'card-site--ready';
        
        return;
      case this.order.status >= 3:
        this.statusTime = (moment(this.order.dates.timestamp_3 || this.order.dates.timestamp_4).format('HH:mm'));
        let startTimeDiff = moment(this.order.dates.timestamp_2).diff(this.order.dates.created_at);        
        this.startTime = ((moment.duration(startTimeDiff).hours() * 60) + moment.duration(startTimeDiff).minutes()).toString();
        let dispatchTimeDiff = moment(this.order.dates.timestamp_3 || this.order.dates.timestamp_4).diff(this.order.dates.created_at);        
        this.dispatchTime = ((moment.duration(dispatchTimeDiff).hours() * 60) + moment.duration(dispatchTimeDiff).minutes()).toString();
        this.classBtn = 'button--outline';
        this.ctaText = this.translate.instant('card.delivered');
        this.classHeader = 'card-site--finished';
        return;
      default:
        break;
    }
  }

  checkRiderStimation(milliseconds: number){
    let timeLeft = (this.order?.rider_arriving_at) ? moment(this.order?.rider_arriving_at).valueOf() - milliseconds : undefined;
    if(!timeLeft || this.order?.rider_arrived) return undefined;
    let minutes = (!!timeLeft && timeLeft > 0) ? moment.duration(timeLeft).minutes() : 1;
    return (minutes > 1) ? minutes : 1;
  }

  get platformName(){
    let classBase: string = 'platform__';
    switch (this.order?.source.toLowerCase()) {
      case 'glovo':
        classBase += 'glovo'
        break;
      case 'uber':
        classBase += 'uber-eats'
        break;
      case 'flipdish':
        classBase += 'flipdish'
        break;
      case 'justeat':
        classBase += 'just-eat'
        break;    
      default:
        classBase += 'stuart'
        break;
    }
    return classBase;
  }

  printOrder(){
    this.userService.printOrder(this.order.firebaseOrder);
  }
}
