import { createReducer, on } from '@ngrx/store';
import { FirebaseSite, SitesData } from '@models';
import * as actions from './sites.actions';

export const initialState: SitesData = { firebaseSites: [], siteSelected: null };

export const sitesReducer = createReducer(initialState,
  on( actions.setSites, (state, { sites }) => setSites(state, sites)),
  on( actions.selectSite, (state, { site }) => setSite(state, site)),
  on( actions.cleanSite, (state) => cleanSite())
);

function setSites(state: SitesData, sites: FirebaseSite[]) {

  if(!sites) return state;
  const Sites: FirebaseSite[] = JSON.parse(JSON.stringify([...sites ]));
  return {...state, firebaseSites: Sites};
}

function setSite(state: SitesData, site: FirebaseSite) {

  if(!site) return state;
  const SiteSelected: FirebaseSite = JSON.parse(JSON.stringify({...site }));
  return {...state, siteSelected: SiteSelected};
}

function cleanSite(){
  return initialState;
}