import { Component } from '@angular/core';

@Component({
  selector: 'app-net-disabled',
  templateUrl: './net-disabled.component.html',
  styleUrls: ['./net-disabled.component.scss'],
})
export class NetDisabledComponent {

  constructor() { }

}
