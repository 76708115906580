import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FlowData, OrderCardData, OrderLine } from '@models/orderCardData.model';
import { StateCard } from '@models/stateCard.model';
import { OrdersStateService, UtilsService } from '@services';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-order-list-pack',
  templateUrl: './order-list-pack.component.html',
  styleUrls: ['./order-list-pack.component.scss'],
})
export class OrderListPackComponent {
  @Input() order: any;
  @Input() dropDownActive: string;
  @Input() updateCard: boolean;
  @Output() dropDownState: EventEmitter<any> = new EventEmitter();
  @Output() clickedItem: EventEmitter<{order: OrderCardData, item: OrderLine}> = new EventEmitter();
  @Output() pressedItem: EventEmitter<{order: OrderCardData, item: OrderLine}> = new EventEmitter();
  @Output() clickedOrder: EventEmitter<{order: OrderCardData, action: string}> = new EventEmitter();
  @Output() orderTimeState: EventEmitter<{order: OrderCardData, timeState: number}> = new EventEmitter();

  dataCard: StateCard = {};

  constructor(
    private ordersStateService: OrdersStateService,
    public translate: TranslateService,
    public utilsService: UtilsService
  ){}

  ngOnInit(){
    this.orderState();
  }

  ngOnChanges(changes:SimpleChanges) {
    if(this.checkOrderToRefresh(changes) || !!changes.updateCard){     
      this.orderState();
    } 
  }

  checkDisableButton(){
    return (this.checkStatusItems() || this.utilsService.waitingFirestoreResponse$.value.find(orderWaiting => orderWaiting === this.order.id));
  }

  trackByItems(item: any ): string {
    return item
  }

  checkOrderToRefresh(changes: SimpleChanges){
    if(!changes.order || !changes.order.previousValue || !changes.order.currentValue) return false;
    const result = _.pickBy(changes.order.currentValue, (v, k) => !_.isEqual(changes.order.previousValue[k], v));
    return !_.isEmpty(result);
  }

  clickInOrder(){
    if(!!this.order.dates.cancelled_at) {
      this.ordersStateService.clickCancelledOrder(this.order);
      return;
    }
    this.clickedOrder.emit({order: this.order, action: this.dataCard.flowStatus});
  }

  toggleDropdown() {
    this.ordersStateService.logOrder(this.order);
    this.dropDownState.emit(this.order.id);
  }

  orderState(){
    this.dataCard.classHeader = '';
    this.dataCard.isGrid = false;
    this.dataCard = this.ordersStateService.checkOrderState(this.order, this.dataCard, this.translate.instant('card.finish'));
  }

  clickItem(item){
    if(!this.order.is_started) return;
    this.clickedItem.emit({order: this.order, item: item})
  }

  longPress(item){
    if(!this.order.is_started) return;
    this.pressedItem.emit({order: this.order, item: item})
  }

  checkFlowItems(item: OrderLine) {
    let canBeChecked: FlowData;

    if(item?.is_combo){
      canBeChecked = this.order.all_flows.find( flow => 
        flow.name !== this.order.station_type && !flow.finished_at && item?.parent_item_number === flow?.parent_item_number)
        
    }
    else if(item?.is_modifier){
      canBeChecked = this.order.all_flows.find( flow => 
        flow.name !== this.order.station_type && !flow.finished_at && item?.parent_item_number === flow?.parent_item_number)
    }else{
      canBeChecked = this.order.all_flows.find( flow => 
        flow.name !== this.order.station_type && !flow.finished_at && 
        (item?.item_number === flow?.parent_item_number || item?.item_number === flow?.item_number))
    }

    return !canBeChecked;
  }

  checkStatusItems(){
    if(!this.order.is_started || this.order.dates.cancelled_at) return false;
    let canBeClosed: OrderLine;
    canBeClosed = this.order.order_lines.find(orderLine => !orderLine?.qty_checks || orderLine?.qty_checks < orderLine.qty);
    return canBeClosed;
  }

}
