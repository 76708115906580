import { ActionReducerMap } from '@ngrx/store';

import { OrdersGrid, PeopleData, BrandModel, SitesData, StatsData, UserData } from '@models';
import { ordersReducer } from '../features/orders/store/orders.reducer';
import { userReducer } from '../features/user/store/user.reducer';
import { stationBrandsReducer } from '../features/station-brands/store/station-brands.reducer';
import { statsReducer } from '../features/stats/store/stats.reducer';
import { configReducer } from '../features/config/store/config.reducer';
import { ConfigData } from '@models/config.model';
import { peopleReducer } from '../features/people/store/people.reducer';
import { sitesReducer } from '../features/sites/store/sites.reducer';
export interface AppState {
    user: UserData,
    stationBrands: BrandModel[],
    orders: OrdersGrid,
    people: PeopleData,
    sites: SitesData,
    stats: StatsData,
    config: ConfigData,
}

export const appReducers: ActionReducerMap<AppState> = {
    user: userReducer,
    stationBrands: stationBrandsReducer,
    orders: ordersReducer,
    people: peopleReducer,
    sites: sitesReducer,
    stats: statsReducer,
    config: configReducer,
}