import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from './../../environments/environment';

import { appReducers } from './app.reducer';
import { TranslateModule } from '@ngx-translate/core';
import { OrdersEffects } from '../features/orders/store/orders.effects';
import { UserEffects } from '../features/user/store/user.effects';
import { StatsEffects } from '../features/stats/store/stats.effects';
import { PeopleEffects } from '../features/people/store/people.effects';
import { SitesEffects } from '../features/sites/store/sites.effects';


@NgModule({
  declarations: [
    // ErrorComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    StoreModule.forRoot( appReducers ),
    StoreDevtoolsModule.instrument({
      maxAge: 25, 
      logOnly: environment.production, 
    }),
    EffectsModule.forRoot([OrdersEffects, PeopleEffects, UserEffects, SitesEffects, StatsEffects]),
    TranslateModule.forChild()
  ]
})
export class CoreModule { }
