import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, switchMap, mergeMap, map } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { FirestoreService, UserService, UtilsService } from '@services';

import * as actions from './user.actions';
import * as actionsConfig from './../../config/store/config.actions';
import { UserData } from '@models/userData.model';
import { NavController } from '@ionic/angular';



@Injectable()
export class UserEffects {

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private firestoreService: FirestoreService,
    private utilsService: UtilsService,
    private navCtrl: NavController
  ) { }

  setUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loginUser),
      switchMap(data => this.userService.loginUser(data.token)
        .pipe(
          mergeMap((user: any) => {
            return [actions.setUser({ user, reload: data.reload })]
          }),
          catchError((err) => {
            console.log("error", err);
            this.utilsService.dismissLoader();
            this.utilsService.showLoginAlert(err.error.message);
            return of(actions.errorLoadingData());
          })
        ))
    )
  });

  setUserSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.setUser),
      switchMap((data) => {
        if(data.reload){
          location.reload();
        }else{
          return this.firestoreService.getConfig(data.user).pipe(
            map((config) => {
              this.utilsService.dismissLoader();
              this.navCtrl.navigateRoot('/');
              return actionsConfig.setConfig({ config })
            }),
            catchError((err) => {
              console.log("error", err);
              this.utilsService.dismissLoader();
              // TODO: Show alert error
              this.utilsService.showLoginAlert('Error al cargar la configuración');
              return of(actions.errorLoadingData());
            })
          )
        }
      }
      )
    )
  });

  // updateUserSites$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(actions.updateUserSite),
  //     switchMap(data => this.userService.updateUserSite(data.token, data.site)
  //       .pipe(
  //         mergeMap((user: any) => {
  //           // this.navCtrl.navigateRoot('/');
  //           return [actions.setUserSite({ user })]
  //         }),
  //         catchError((err) => {
  //           console.log("error", err);
  //           return of(actions.errorLoadingData());
  //         })
  //       ))
  //   )
  // });
}