
export const environment = {
   production: false,
   api_sites_url: 'https://cuynaos-6tgy1xr0.ew.gateway.dev/v1/',
   api_ocs_url: 'https://ocs-api-cmiu2mkpla-ew.a.run.app/v1/',
   api_inventory_url: 'https://cuynaos-6tgy1xr0.ew.gateway.dev/v1/',
   firebase: {
      apiKey: 'AIzaSyCmdrgtEuZuIUySWxOW5A5XWy9z23L44BA',
      authDomain: 'kiss-366509.firebaseapp.com',
      projectId: 'kiss-366509',
      storageBucket: 'kiss-366509.appspot.com',
      messagingSenderId: '309585234035',
      appId: '1:309585234035:web:8252f86c66117b469c8206'
   },
   last_api_token: '3b2707f5-1f28-4f44-87ad-c2fe5ac35fa2',
   last_api_url: 'https://api.last.app/v1',
   server_time: 'https://europe-west1-kiss-366509.cloudfunctions.net/getServerTime',
   cloud_storage_domain: 'https://storage.googleapis.com'
};
