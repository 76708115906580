import { createReducer, on } from '@ngrx/store';
import { BrandModel } from '@models';
import * as actions from './station-brands.actions';


export const initialState: BrandModel[] = [];

export const stationBrandsReducer = createReducer(initialState,
  on( actions.fetchStationBrands, (state, { brands }) => fetchStationBrands(state, brands)),
  on( actions.updateStationBrands, (state, { brands }) => setStationBrands(state, brands)),
);

function fetchStationBrands(state: BrandModel[], stationBrandsUser: BrandModel[]) {  
  
  if(!stationBrandsUser || !stationBrandsUser.length) return state;

  return stationBrandsUser as BrandModel[];
}

function setStationBrands(state: BrandModel[], restaurantsOcs: any) {  
  
  if(!restaurantsOcs || !restaurantsOcs.length) return initialState;
  let parseStationRestaurants: BrandModel[] = JSON.parse(JSON.stringify([...state]));
  let settedStationRestaurants: BrandModel[] = [];

  parseStationRestaurants.forEach((restaurant: BrandModel) => {
    let restaurantOcsData: any = restaurantsOcs.find((rest: any) => rest.id === restaurant.external_id && rest.active);
    if(restaurantOcsData) {
      restaurant.is_open = ((!restaurantOcsData.glovoClosedUntil && restaurantOcsData.glovoActive) || (!restaurantOcsData.justeatClosedUntil && restaurantOcsData.justeatActive) || (!restaurantOcsData.uberClosedUntil && restaurantOcsData.uberActive));
      settedStationRestaurants.push(restaurant);
    }
  });

  localStorage.setItem('stationRestaurants', JSON.stringify(settedStationRestaurants));

  return settedStationRestaurants as BrandModel[];
}