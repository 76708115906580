import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { batchItem } from '@models/batchSet.model';
import { SetCard } from '@models/card.model';
import { UtilsService } from '@services';
import * as moment from 'moment';

@Component({
  selector: 'app-batch-orders',
  templateUrl: './batch-orders.component.html',
  styleUrls: ['./batch-orders.component.scss'],
})
export class BatchOrdersComponent implements OnInit {

  @Input() batchesSet: SetCard;
  @Input() batchesSetIndex: number;
  @Input() itemInProcess: batchItem | null;
  @Output() clickedBatch: EventEmitter<{batchesSet: any, action: string}> = new EventEmitter();
  @Output() addBatchItem: EventEmitter<{item: batchItem, action: string}> = new EventEmitter();
  @Output() resetBatchInProccess: EventEmitter<any> = new EventEmitter();

  checksAnimation = false;

  item: batchItem
  itemClicked: number | null = null;

  statusTime: string;

  constructor(private utilsService: UtilsService) { }

  ngOnInit() {}

  trackByItems(item: any ): string {
    return item
  }

  addToCurrentBatch(item: batchItem){
    this.addBatchItem.emit({item, action: 'started_at'});
  }

  clickBatch(){
    const currentState = (this.batchesSet.is_active) ? 'finished_at' : 'started_at';
    this.utilsService.strikedItems$.next([]);
    this.clickedBatch.emit({batchesSet: this.batchesSet, action: currentState});
  }

  currentTime(batchesSet: SetCard){
    let time = '';
    if(batchesSet.batches.length > 0){
      batchesSet.batches.find(batch => !!batch.items.find(item => !!item.item_orders.find(order => !!order.order_line.product.flow.find(flow => {
        if(flow.started_at){
          time = flow.started_at;
        }
      }))));
    }

    const diffTime = moment().diff(moment(time), 'milliseconds');

    return moment(diffTime).format('mm:ss');

  }

  resetBatchItemInProccess(){
    this.resetBatchInProccess.emit();
  }

  triggerAnimation() {
    this.checksAnimation = true;
    const resetTimeOut$ = setTimeout(() => { 
      this.clickBatch();
      clearTimeout(resetTimeOut$);
    }, 350);

    const animationTimeOut$ = setTimeout(() => { 
      this.checksAnimation = false;
      clearTimeout(animationTimeOut$);      
    }, 850);
  }
}
