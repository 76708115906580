import { Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';

export type ResizeAnchorType =
  | 'top'
  | 'left'
  | 'bottom'
  | 'right'

export type ResizeDirectionType =
  | 'x'
  | 'y'
  | 'xy';

@Component({
  selector: 'app-video-frame',
  templateUrl: './video-frame.component.html',
  styleUrls: ['./video-frame.component.scss'],
})
export class VideoFrameComponent {

  // @ViewChild('resizeCorner') resizeCornerRef!: ElementRef;


  @ViewChild('wrapper') wrapperRef!: ElementRef;
  @ViewChild('video') videoContainer!: ElementRef;
  @ViewChild('contentWrapper') contentWrapper!: ElementRef;
  @ViewChild('dragIcon') dragIcon!: ElementRef;
  @Input() sendingPhoto: boolean = false;
  @Output() cameraAvailableState: EventEmitter<boolean> = new EventEmitter();

  // @ViewChild('topBar') topBarRef!: ElementRef;

  cameraAvailable = true;

  position: { x: number, y: number } = { x: 100, y: 100 };

  size: { w: number, h: number } = { w: 200, h: 200 };

  lastPosition: { x: number, y: number };

  refreshVideoTag:number = 600000;

  // lastSize: { w: number, h: number };

  // minSize: { w: number, h: number } = { w: 200, h: 200 };

  constructor(@Inject(DOCUMENT) private _document: Document,
    private _el: ElementRef) { }

  ngAfterViewInit() {
    if (this.cameraAvailable) {
      // Get screen dimensions
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      // Get the element's dimensions
      const elementWidth = this.videoContainer.nativeElement.offsetWidth;
      const elementHeight = this.videoContainer.nativeElement.offsetHeight;

      this.size.w = elementWidth;
      this.size.h = elementHeight + (elementHeight / 100) * 12;
      const vw = Math.max(screenWidth - elementWidth) / 15;
      this.position.x = screenWidth - elementWidth - vw;

      const vh = Math.max(screenHeight - elementHeight) / 5;
      this.position.y = screenHeight - elementHeight - vh;

      this.initWebCam();

      this.cameraAvailableState.emit(this.cameraAvailable);
    }
  }

  async initWebCam() {
    try {
      //shows the webcam over the video tag
      const video = document.getElementById('webcam') as HTMLVideoElement;
      if (navigator.mediaDevices.getUserMedia) {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');

        if (!videoDevices || !videoDevices.length) {
          this.cameraAvailable = false;
          this.cameraAvailableState.emit(this.cameraAvailable);
          return;
        }

        videoDevices.forEach((device) => {
          console.log(`${device.label} id = ${device.deviceId}`);
        });

        // Choose the first video device (usually the rear camera on a phone)
        const frontCamera = videoDevices[0];
        // If there's another video device, choose that one (usually the front camera on a phone)
        // const rearCamera = videoDevices.length > 1 ? videoDevices[1] : null;
        const constraints = {
          video: {
            deviceId: frontCamera.deviceId,
            // deviceId: rearCamera ? rearCamera.deviceId : frontCamera.deviceId,
            // width: { min: 1080, max: 5000 }
            width: { min: 640, max: 5000 },
          }
        };

        // const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();


        const stream = await navigator.mediaDevices.getUserMedia(constraints)
          .then((stream) => {
            return stream;
          })
          .catch((error) => {
            this.cameraAvailable = false;
            this.cameraAvailableState.emit(this.cameraAvailable);
            // if(error.message.includes("Permission denied")){
            //   this.cameraAvailable = false;
            //   this.cameraAvailableState.emit(this.cameraAvailable);
            // }
          });
        if (!stream) return;

        //ver el window.orientation para ver si esta en landscape o portrait, y si esta en portrait, rotar el video
        console.log("window.orientation", window.orientation);

        // if(window.innerHeight > window.innerWidth) {
        //   this.contentWrapper.nativeElement.style.transform = `rotate(${window.orientation + 'deg'})`;
        //   // this.contentWrapper.nativeElement.style.transform = '100%';
        // }

        video.srcObject = stream;

        video.onloadedmetadata = () => {
          console.log('Video dimensions:', video.videoWidth, 'x', video.videoHeight);
          if (video.videoWidth < video.videoHeight) {
            // this.contentWrapper.nativeElement.style.transform = `rotate(${window.orientation + 'deg'})`;
            this.contentWrapper.nativeElement.style.transform = `rotate(${90 + 'deg'})`;
            this.dragIcon.nativeElement.style.transform = `rotate(${90 + 'deg'})`;
            this.size.w = this.videoContainer.nativeElement.offsetHeight;
            this.size.h = this.videoContainer.nativeElement.offsetWidth + (this.videoContainer.nativeElement.offsetWidth / 100) * 12;
          }else{
            this.size.w = this.videoContainer.nativeElement.offsetWidth;
            this.size.h = this.videoContainer.nativeElement.offsetHeight + (this.videoContainer.nativeElement.offsetHeight / 100) * 12;
          }
          // this.handleOrientationChange(window.orientation);
          // You can also adjust the orientation based on the video dimensions if needed
          // handleOrientationChange(window.orientation);
        };

        const refreshVideo$ = setTimeout(() => {
          video.srcObject = null;
          this.initWebCam();
          clearTimeout(refreshVideo$);
        }, this.refreshVideoTag); 

      } else {
        console.log('no webcams present');
        this.cameraAvailable = false;
        this.cameraAvailableState.emit(this.cameraAvailable);
        // no webcams present
      }
    } catch (error) {
      console.log(error);
      this.cameraAvailable = false;
      this.cameraAvailableState.emit(this.cameraAvailable);
    }
  }

  startDrag($event): void {
    $event.preventDefault();

    // Get screen dimensions
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    // Get the element's dimensions
    const elementWidth = this.videoContainer.nativeElement.offsetWidth;
    const elementHeight = this.videoContainer.nativeElement.offsetHeight;

    // const elementWidth = (window.innerHeight > window.innerWidth) ? this.videoContainer.nativeElement.offsetHeight : this.videoContainer.nativeElement.offsetWidth;
    // const elementHeight = (window.innerHeight > window.innerWidth) ? this.videoContainer.nativeElement.offsetWidth : this.videoContainer.nativeElement.offsetHeight;

    console.log("startDrag elementWidth", elementWidth);
    console.log("startDrag elementHeight", elementHeight);
    


    const mouseX = $event.clientX || $event.touches[0].clientX;
    const mouseY = $event.clientY || $event.touches[0].clientY;

    const positionX = this.position.x;
    const positionY = this.position.y;

    const duringDrag = (e) => {
      const dx = (e.clientX || e.touches[0].clientX) - mouseX;
      const dy = (e.clientY || e.touches[0].clientY) - mouseY;

      // Calculate new positions
      let newX = positionX + dx;
      let newY = positionY + dy;

      // Check if new positions are within screen bounds
      if (newX < 0) newX = 0;
      if (newY < 0) newY = 0;
      if (newX > screenWidth - elementWidth) newX = screenWidth - elementWidth;
      if (newY > screenHeight - elementHeight) newY = screenHeight - elementHeight;

      // Update positions
      this.position.x = newX;
      this.position.y = newY;
      this.lastPosition = { ...this.position };
    };

    const finishDrag = (e) => {
      this._document.removeEventListener('mousemove', duringDrag);
      this._document.removeEventListener('mouseup', finishDrag);
      this._document.removeEventListener('touchmove', duringDrag);
      this._document.removeEventListener('touchend', finishDrag);
    };

    this._document.addEventListener('mousemove', duringDrag);
    this._document.addEventListener('mouseup', finishDrag);
    this._document.addEventListener('touchmove', duringDrag);
    this._document.addEventListener('touchend', finishDrag);
  }

  // startResize($event, anchors: ResizeAnchorType[], direction: ResizeDirectionType): void {
  //   $event.preventDefault();
  //   const mouseX = $event.clientX;
  //   const mouseY = $event.clientY;
  //   const lastX = this.position.x;
  //   const lastY = this.position.y;
  //   // const dimensionWidth = this.resizeCornerRef.nativeElement.parentNode.offsetWidth;
  //   // const dimensionHeight = this.resizeCornerRef.nativeElement.parentNode.offsetHeight;

  //   const duringResize = (e) => {
  //     let dw = dimensionWidth;
  //     let dh = dimensionHeight;
  //     if (direction === 'x' || direction === 'xy') {
  //       if (anchors.includes('left')) {
  //         dw += ( mouseX - e.clientX );
  //       } else if (anchors.includes('right')) {
  //         dw -= ( mouseX - e.clientX );
  //       }
  //     }
  //     if (direction === 'y' || direction === 'xy') {
  //       if (anchors.includes('top')) {
  //         dh += ( mouseY - e.clientY );
  //       } else if (anchors.includes('bottom')) {
  //         dh -= ( mouseY - e.clientY );
  //       }
  //     }

  //     if (anchors.includes('left')) {
  //       this.position.x = lastX + e.clientX - mouseX;
  //       this.size.w = Math.max(dw, this.minSize.w);
  //     }

  //     if (anchors.includes('top')) {
  //       this.position.y = lastY + e.clientY - mouseY;
  //       this.size.h = Math.max(dh, this.minSize.h);
  //     }

  //     if (anchors.includes('bottom') || anchors.includes('right')) {
  //       this.size.w = Math.max(dw, this.minSize.w);
  //       this.size.h = Math.max(dh, this.minSize.h);
  //     }

  //     this.lastSize = { ...this.size };
  //   };

  //   const finishResize = (e) => {
  //     this._document.removeEventListener('mousemove', duringResize);
  //     this._document.removeEventListener('mouseup', finishResize);
  //   };

  //   this._document.addEventListener('mousemove', duringResize);
  //   this._document.addEventListener('mouseup', finishResize);
  // }
}
