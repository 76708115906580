import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { UtilsService } from '@services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private navCtrl: NavController, private utilsService: UtilsService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!localStorage.getItem('token')) {
      this.navCtrl.navigateRoot('/login');
      return false;
    }else if(!this.utilsService.serverTime$.value || !this.utilsService.initTime$.value){
      this.navCtrl.navigateRoot('/loader');
      return false;
    }else{
      return true;
    }
  }
  
}
