/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OrderCardData, OrderLine } from '@models/orderCardData.model';
import { StatsData } from '@models/stats.model';
import { Store } from '@ngrx/store';
import { UtilsService } from '@services';
import { Constants } from '@share/constants';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { AppState } from 'src/app/core/app.reducer';
import { FirestoreService } from '@services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-checklist-items',
  templateUrl: './checklist-items.component.html'
})
export class ChecklistItemsComponent implements OnInit, OnDestroy {
  cameraAvailable = true;
  countNumber = 0;
  order: OrderCardData;
  settedOrder: any;
  finishedStatus = '';
  finishedMessage = '';
  checklistAnimation = false;
  finishedAnimation = false;
  finishOrderTimes: any = {};
  checksAnimation = false;
  stats: StatsData;
  stats$: Observable<StatsData> = this.store.select(state => state.stats);
  statsSubscription$: Subscription;
  timeRestaurant = '';
  timeSite = '';

  timerToClose: any;
  timerToShow: any;
  


  constructor(
    private modalController: ModalController,
    private utilsService: UtilsService,
    private store: Store<AppState>,
    private firestore: FirestoreService
  ) {
    this.statsSubscription$ = this.stats$.subscribe(stats => {
      this.stats = stats;
    });
  }

  ngOnDestroy() {

    this.statsSubscription$.unsubscribe();
    // this.closeWebCam();
  }

  ngOnInit() {
    this.settedOrder = JSON.parse(JSON.stringify(this.order));
    const settedOrderLines: any[] = [];

    this.settedOrder.order_lines.forEach((orderLine: OrderLine) => {
      if (orderLine.is_combo) {
        const newOrderLine: any = {
          name: orderLine.product.kitchen_description || orderLine.product.description,
          notes: orderLine.notes,
          qty: orderLine.qty,
          qty_checks: orderLine.qty_checks,
          warning: orderLine.product.flow.length === 1 && orderLine.product.flow[0].name === 'pack',
          type: 'combo',
        };
        settedOrderLines.push(newOrderLine);
      } else if (!orderLine.is_combo && !orderLine.is_modifier) {
        const newOrderLine: any = {
          item_number: orderLine.item_number,
          name: orderLine.product.kitchen_description || orderLine.product.description,
          notes: orderLine.notes,
          qty: orderLine.qty,
          qty_checks: orderLine.qty_checks,
          warning: orderLine.product.flow.length === 1 && orderLine.product.flow[0].name === 'pack',
          type: 'item'
        };
        settedOrderLines.push(newOrderLine);
      } else if (orderLine.is_modifier) {
        const newOrderLine: any = {
          name: orderLine.product.kitchen_description || orderLine.product.description,
          item_number: orderLine.item_number,
          parent_item_number: orderLine.parent_item_number,
          qty: orderLine.qty,
          qty_checks: orderLine.qty_checks,
          warning: orderLine.product.flow.length === 1 && orderLine.product.flow[0].name === 'pack',
          type: 'modifier'
        };
        settedOrderLines.push(newOrderLine);
      }
    });
    this.settedOrder.order_lines = settedOrderLines;
  }

  ngAfterViewInit() {
    if(this.cameraAvailable) this.initWebCam();
  }

  countUp(totalDuration) {
    const finishCount = 100;
    const interval = (totalDuration / finishCount);

    const countInterval = setInterval(() => {
      if (this.countNumber >= finishCount) {
        clearInterval(countInterval);
        return;
      }

      this.countNumber++;
    }, interval);
  }

  checkDisableButton() {
    return this.settedOrder.order_lines.find(orderLine => orderLine.qty_checks !== orderLine.qty);
  }

  private checkOrderState() {

    const nowTime: number = this.utilsService.getTimeStamp();
    const difference: number = (this.settedOrder?.finish_time_estimated - nowTime) / 1000;

    this.finishedAnimation = true;

    switch (true) {
      case difference > Constants.WARNING_STATUS_TIME:
        this.finishedStatus = 'success';
        this.finishedMessage = this.utilsService.getRandomMessage('success');
        break;
      case difference > 0 && difference < Constants.WARNING_STATUS_TIME:
        this.finishedStatus = 'warning';
        this.finishedMessage = this.utilsService.getRandomMessage('warning');
        break;
      default:
        this.finishedStatus = 'alert';
        this.finishedMessage = this.utilsService.getRandomMessage('alert');
        break;
    }
  }

  updateItemCheck(orderLine: any, event: string) {
    this.clickLine(orderLine, event);
    if (orderLine.type === 'item') {
      this.checkModifiers(orderLine);
    }
    this.triggerAnimation();
  }

  checkModifiers(settedOrderLine: any) {
    if (settedOrderLine.qty_checks === 0) {
      this.settedOrder.order_lines.forEach((orderLine: any) => {
        if (!!orderLine.parent_item_number && orderLine.parent_item_number === settedOrderLine.item_number) {
          orderLine.qty_checks = 0;
        }
      });
    } else if (settedOrderLine.qty === settedOrderLine.qty_checks) {
      this.settedOrder.order_lines.forEach((orderLine: any) => {
        if (!!orderLine.parent_item_number && orderLine.parent_item_number === settedOrderLine.item_number) {
          orderLine.qty_checks = orderLine.qty;
        }
      });
    }
  }

  clickLine(orderLine: any, event: string) {
    if (orderLine.qty === orderLine.qty_checks) {
      orderLine.qty_checks = 0;
    } else if (event === 'press') {
      orderLine.qty_checks = orderLine.qty;
    } else if (event === 'click') {
      orderLine.qty_checks = orderLine.qty_checks + 1;
    }
  }

  close() {
    this.order.order_lines.forEach((orderLine: OrderLine) => {
      const existOrderLine = this.settedOrder.order_lines.find(settedOrderline => settedOrderline.item_number === orderLine.item_number);
      if (existOrderLine) {
        orderLine.qty_checks = existOrderLine.qty_checks;
      }

      return orderLine;
    });



    this.modalController.dismiss({ action: 'close', order: this.order });
  }

  closeFinishedButton() {
    clearTimeout(this.timerToClose);
    this.closeFinished();
  }

  closeFinished() {
    if (this.timerToClose) {
      this.modalController.dismiss({ action: 'finish', order: this.order });
    }
  }

  async isCamAvailable() {
    return new Promise((resolve) => {
      const md = navigator.mediaDevices;
      if (!md || !md.enumerateDevices) { return resolve(false); }
      md.enumerateDevices().then(devices => {
        resolve(devices.some(device => 'videoinput' === device.kind));
      });
    });
  }

  async closeWebCam(video: any) {
    //close the video signal
    //const video = document.getElementById('video') as HTMLVideoElement;
    const stream = video?.srcObject as MediaStream;
    //let streaming;


    const tracks = stream?.getTracks();

    tracks.forEach(track => {
      track.stop();
    });
    video.srcObject = null;
  }

  async initWebCam() {
    try {
      //shows the webcam over the video tag
      const video = document.getElementById('webcam') as HTMLVideoElement;
      if (navigator.mediaDevices.getUserMedia) {

        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
        console.log(videoDevices);
        console.log(supportedConstraints);

        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: { min: 1080,  max: 5000 }
          }
        });
        const settings = stream.getVideoTracks()[0].getSettings();
        console.log(settings);

        video.srcObject = stream;
      } else {
        // no webcams present
      }
    } catch (error) {
      console.log(error);
      this.cameraAvailable = false;
    }
  }

  async captureWebCamPhoto() {
    if (this.cameraAvailable) {
      //captures the snapshot of the webcam
      const video = document.getElementById('webcam') as HTMLVideoElement;
      const canvas = document.getElementById('webcam-canvas') as HTMLCanvasElement;
      const context = canvas.getContext('2d');

      canvas.width = video.videoWidth ;
      canvas.height = video.videoHeight ;

      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight, 0, 0, canvas.width, canvas.height);
      const dataUrl = canvas.toDataURL('image/jpeg');

      this.closeWebCam(video);

      const resultUpload = this.firestore.uploadFile(dataUrl, `order_${this.order.id}.jpg`, 'images');
      resultUpload.subscribe((url) => {
        const photo_path: string = `${environment.cloud_storage_domain}/${url.ref._delegate._location.bucket}/${url.ref._delegate._location.path_}`;
        let orderToUpdate: OrderCardData = JSON.parse(JSON.stringify(this.order));
        orderToUpdate.photo_url = photo_path;
        orderToUpdate.own_image = true;
        this.order = orderToUpdate;
        this.displayFinalAnimation();
      })

      
    } else {
      this.displayFinalAnimation();
    }
  }

  displayFinalAnimation() {
    this.checklistAnimation = true;

    this.timerToShow = setTimeout(() => {
      const milliseconds: number = this.utilsService.getTimeStamp();

      this.finishOrderTimes = {
        orderTime: moment(moment(milliseconds).diff(moment(this.order.dates.timestamp_0))).format('mm:ss'),
        brandTime: moment(this.stats.firebaseStats.restaurants.find(restaurant => restaurant.internal_id === this.settedOrder.restaurant.id).prep_time_med).format('mm:ss') || '--:--',
        siteTime: moment(this.stats.firebaseStats.totals.prep_time_med).format('mm:ss') || '--:--',
      };
      this.checkOrderState();
      this.timeRestaurant = this.getTimeStatus(this.stats.firebaseStats.restaurants.find(restaurant => restaurant.internal_id === this.settedOrder.restaurant.id).prep_time_med);
      this.timeSite = this.getTimeStatus(this.stats.firebaseStats.totals.prep_time_med);
      clearTimeout(this.timerToShow);
    }, 500);

    this.countUp(5000);

    this.timerToClose = setTimeout(() => {
      this.closeFinished();
      clearTimeout(this.timerToClose);
    }, 5000);
  }

  getTimeStatus(time: number) {
    if (time <= 600000) {
      return 'success';
    } else if (time > 600000 && time <= 720000) {
      return 'warning';
    } else {
      return 'alert';
    }
  }

  get platformName() {
    let classBase = 'platform__';
    switch (this.order?.source.toLowerCase()) {
      case 'glovo':
        classBase += 'glovo';
        break;
      case 'uber':
        classBase += 'uber-eats';
        break;
      case 'flipdish':
        classBase += 'flipdish';
        break;
      case 'justeat':
        classBase += 'just-eat';
        break;
      default:
        classBase += 'stuart';
        break;
    }
    return classBase;
  }

  triggerAnimation() {
    this.checksAnimation = true;
    setTimeout(() => { this.checksAnimation = false; }, 350);
  }
}
