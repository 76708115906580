import { Component, Input, OnInit } from '@angular/core';
import { StatsData } from '@models/stats.model';
import { UserData } from '@models/userData.model';
import * as moment from 'moment';
import { AlertController, ModalController } from '@ionic/angular';
import { SaleItemsRelatedComponent } from '../sale-items-related/sale-items-related.component';
import { UserService, WarehouseItemsService } from '@services';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, filter } from 'rxjs/operators';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  @Input() user$: Observable<UserData>;
  @Input() stats$: Observable<StatsData>;
  activeTab: string = '';
  isModalActive: boolean = false;
  math = Math;
  stockBreaks: any = [];
  tokenActive: string = '';

  constructor(
    private alertController: AlertController,
    private modalController: ModalController,
    private userService: UserService,
    private warehouseItemsService: WarehouseItemsService
  ) {
    this.activeTab = 'dashboard';
  }

  ngOnInit() {
    if (localStorage.getItem('token')) {
      this.tokenActive = localStorage.getItem('token');
      this.getStockBreaks()
    }
  }

  checkTotalTime(data, type){
    if(!data) return "---";
    let totalTime = 0;
    if(!!data.stc_time) totalTime += data.stc_time;
    if(!!data.prep_time) totalTime += data.prep_time;
    if(type === 'total' && !!data.disp_time) totalTime += data.disp_time;
    const time = moment.duration(totalTime/(data.num_orders || 1));
    const settedTime = ((time.hours()*60 + time.minutes()) + time.seconds()/60).toFixed(0);
    return `${settedTime} min.`;
  }

  checkData(firebaseStats, type, restaurant?){

    if(!firebaseStats) return "---";

    const brandStats = firebaseStats.restaurants.find(fireRestaurant => fireRestaurant.internal_id === restaurant.external_id);
    if(!brandStats) return "---";
    
    let totalTime = 0;
    let parseMinTime;
    switch (type) {
      case 'prep':
        if(!!brandStats.stc_time) totalTime += brandStats.stc_time;
        if(!!brandStats.prep_time) totalTime += brandStats.prep_time;

        parseMinTime = moment.duration(totalTime/brandStats.num_orders);
    
        return `${((parseMinTime.hours()*60 + parseMinTime.minutes()) + parseMinTime.seconds()/60).toFixed(0)} min.`;

      case 'total':
        // let totalTime = 0;
        if(!!brandStats.stc_time) totalTime += brandStats.stc_time;
        if(!!brandStats.prep_time) totalTime += brandStats.prep_time;
        if(!!brandStats.disp_time) totalTime += brandStats.disp_time;

        parseMinTime = moment.duration(totalTime/brandStats.num_orders);
    
        return `${((parseMinTime.hours()*60 + parseMinTime.minutes()) + parseMinTime.seconds()/60).toFixed(0)} min.`;

      case 'orders':
        return brandStats.num_orders || 0;
    
      default:
        break;
    }
  }

  changeContent(event) {
    this.activeTab = event.id;
  }

  getStockBreaks() {
    const user: UserData = JSON.parse(localStorage.getItem('user'));
    let getItems$: Subscription = this.warehouseItemsService.getStockBreaks(user.site.id).pipe(
      filter(items => !!items),
      map(items => {
        const settedItems = items.filter(item => item.consumption_forecasts.length > 0 && item.consumption_forecasts[0].daily_consumption_quantity >= 1);
        settedItems.sort((a: any, b: any) => (b.consumption_forecasts[0].daily_consumption_quantity - b.stocks_info[0].current_quantity) - (a.consumption_forecasts[0].daily_consumption_quantity - a.stocks_info[0].current_quantity));
        return settedItems
      })
    ).subscribe(
      (items: any) => { 
        this.stockBreaks = items;
      getItems$.unsubscribe();
    },
    error =>{ 
      console.log("getStockBreaks error", error);
      if(error.status == 0 || error.status == 401){
        this.userService.setToken().subscribe(token => {
          localStorage.setItem('token', token);
          getItems$.unsubscribe();
          this.getStockBreaks()
        },
        error =>{
          console.log('error: ', error);
          getItems$.unsubscribe();
        });
      }
    });  
  }

  async openSaleItems(data: any) {
    const modal =  await this.modalController.create({
      component: SaleItemsRelatedComponent,
      componentProps: { dataModal: data },
      cssClass: 'custom-modal',
      backdropDismiss: false,
    });

    await modal.present();

    modal.onDidDismiss().then(() => {
      this.isModalActive = false;
    })
  }
}
