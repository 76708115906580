import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, switchMap, mergeMap, map, exhaustMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FirestoreService } from '@services';
import * as actions from './orders.actions';


 
@Injectable()
export class OrdersEffects {
  
  loadOrders$ = createEffect(() => {

    return this.actions$.pipe(
      ofType(actions.fetchOrders),
      switchMap((data) => 
        this.firestoreService.getOrders(data.user, data.config).pipe(
          // takeUntil(this.firestoreService.unsubscribe$),
          map((orders) => actions.setOrders({orders})),
          catchError((err) => {
            console.log("error", err);
            return of(actions.errorLoadingData());
          })
        )
      )
    ) 
  });

  updateOrderStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.updateOrderStatus),
      exhaustMap((data) => 
        this.firestoreService.updateStatus(data.order, data.people, data.user).pipe(
          mergeMap(() => {
            return []
          }),
          catchError((err) => {
            console.log("error", err);
            return of(actions.errorLoadingData());
          })
        )
      )
    )
  });

  updateOrderFlow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.updateOrderFlow),
      exhaustMap((data => this.firestoreService.updateOrderFlow(data.order, data.action, data.user, data.people)
        .pipe(
          mergeMap(() => {
            return []
          }),
          catchError((err) => {
            console.log("error", err);
            return of(actions.errorLoadingData());
          })
        )
      ))
    )
  });

  addBatchItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.addBatchItem),
      exhaustMap((data =>{
        return this.firestoreService.addBatchItem(data.batchItem, data.action, data.user, data.people)
        .pipe(
          mergeMap(() => {
            return []
          }),
          catchError((err) => {
            console.log("error", err);
            return of(actions.errorLoadingData());
          })
        )

      } 
      ))
    )
  });

  updateOrderPhoto$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.updateOrderPhoto),
      exhaustMap((data =>{
        return this.firestoreService.updateOrderPhoto(data.order)
        .pipe(
          mergeMap(() => {
            return []
          }),
          catchError((err) => {
            console.log("error", err);
            return of(actions.errorLoadingData());
          })
        )

      } 
      ))
    )
  });

  updateBatch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.updateBatch),
      exhaustMap((data =>{
        return this.firestoreService.updateBatch(data.batchesSet, data.action, data.user, data.people)
        .pipe(
          mergeMap(() => {
            return []
          }),
          catchError((err) => {
            console.log("error", err);
            return of(actions.errorLoadingData());
          })
        )

      } 
      ))
    )
  });

  updateItemCheck$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.updateItemCheck),
      exhaustMap((data => this.firestoreService.updateItemCheck(data.order, data.item, data.event)
        .pipe(
          mergeMap(() => {
            return []
          }),
          catchError((err) => {
            console.log("error", err);
            return of(actions.errorLoadingData());
          })
        )
      ))
    )
  });

  constructor(
    private readonly actions$: Actions,
    private firestoreService: FirestoreService,
  ) {}
}