import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProxyService {
  token: string = '';
  private localRequests = 0;
  private globalRequests = 0;

  constructor(public http: HttpClient) { }

  protected get(url: string, isLocal: boolean = false, options: any = null, loaderType: string = 'global'): Observable<any> {
    if (options) {
      return this.http.get(url, options)
    } else {
      return this.http.get(url)
    }
  }

  protected post(url: string, data: any, isLocal = false, isFile = false, loaderType: string = 'global'): Observable<any> {
    const headerToken = this.addHeaderToken(isLocal, isFile);
    return this.http.post(url, data, headerToken)
  }

  protected put(url: string, data: any, isLocal = false, loaderType: string = 'global'): Observable<any> {
    return this.http.put(url, data)
  }

  protected patch(url: string, data: any, isLocal = false, loaderType: string = 'global'): Observable<any> {
    return this.http.patch(url, data)
  }

  protected delete(url: string, isLocal = false, loaderType: string = 'global'): Observable<any> {
    return this.http.delete(url)
  }

  private addHeaderToken(isLocal: boolean, isFile: boolean) {
    let headers;
    if (isLocal) {
      headers = new HttpHeaders({
        'x-apigateway-api-userinfo': 'eyJuYW1lIjoiRGF2aWQgR29uesOhbGV6IiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hLS9BQ05QRXU5N1laT1NTUXNWSVUtSldIRGpHaEhVT0lTVGdQVlB5ekVNbHBkdz1zOTYtYyIsImlzcyI6Imh0dHBzOi8vc2VjdXJldG9rZW4uZ29vZ2xlLmNvbS9yaWRlcnNhcHAtc3RnIiwiYXVkIjoicmlkZXJzYXBwLXN0ZyIsImF1dGhfdGltZSI6MTY2Mzc1MjMxNiwidXNlcl9pZCI6Inljd2s5elJOZ2VQMG9hSFFXQ1VkZGk2NDlCRTIiLCJzdWIiOiJ5Y3drOXpSTmdlUDBvYUhRV0NVZGRpNjQ5QkUyIiwiaWF0IjoxNjY0NzkwMzU3LCJleHAiOjE2NjQ3OTM5NTcsImVtYWlsIjoiZGF2aWQuZ29uemFsZXpAY3V5bmEuZXMiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJnb29nbGUuY29tIjpbIjEwOTQyNDQ0MjQxNjMzOTA0MTA2NiJdLCJlbWFpbCI6WyJkYXZpZC5nb256YWxlekBjdXluYS5lcyJdfSwic2lnbl9pbl9wcm92aWRlciI6Imdvb2dsZS5jb20ifX0'
      });
    } else if (!isFile) {
      const tokenValue: string = `Bearer ${localStorage.getItem('token')}`;
      headers = new HttpHeaders({
        'authorization': tokenValue
      });
    } else {
      const tokenValue: string = `Bearer ${localStorage.getItem('token')}`;
      headers = new HttpHeaders({
        'authorization': tokenValue,
      });
    }
    const requestOptions = { headers: headers };
    return requestOptions
  }

  handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<T> => {
      console.log('error', operation, error)
      return of(result);
    };
  }

  handleSucces<T>(operation = 'operation', result?: any) {
    return (res: any): Observable<T> => {
      console.log('succes', operation, res)
      return of(result);
    };
  }
}
