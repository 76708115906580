
import { createSelector } from '@ngrx/store';
import { AppState } from '../../../core/app.reducer';

export const selectItems = (state: AppState) => state.orders.ordersCardData;

export const getItemById = (id) => createSelector(selectItems, (allItems) => {
  if (allItems) {
    return allItems.find(item => {
      return item.id === id;
    });
  } else {
    return {};
  }
});