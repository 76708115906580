import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateClock'
})
export class DateClockPipe implements PipeTransform {

  transform(values: string | undefined, args: number): string {
    const createdMillisecond: number = moment(values).valueOf();
    const dateMilliseconds: number = createdMillisecond + args;
    const clock = moment(dateMilliseconds).format('HH:mm');
    return clock;
  }

}
