import { createAction, props } from '@ngrx/store';
import { Constants } from '@share/constants';
import { FirebaseSite } from '@models';


export const fetchSites = createAction(
    Constants.ACTION_SITES_FETCH
);

export const setSites = createAction(
    Constants.ACTION_SITES_SET,
    props<{ sites: FirebaseSite[] }>()
);

export const selectSite = createAction(
    Constants.ACTION_SITES_SELECT,
    props<{ site: FirebaseSite }>()
);

export const cleanSite = createAction(
    Constants.ACTION_SITES_CLEAN
);

export const errorLoadingData = createAction(
    Constants.ACTION_ORDERS_ERROR
);