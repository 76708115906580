import { Component, Inject, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-sale-items-related',
  templateUrl: './sale-items-related.component.html',
  styleUrls: ['./sale-items-related.component.scss'],
})
export class SaleItemsRelatedComponent implements OnInit {
  @Input() dataModal: any;

  constructor(private modalController: ModalController) { }

  ngOnInit() {
    console.log("dataModal", this.dataModal);
    
  }
  getBrands(brands){
    return brands.map(brand => brand.name).join(' • ');
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
