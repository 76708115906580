import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UtilsService } from './utils.service';
import { Constants } from '@share/constants';
import { Store } from '@ngrx/store';
import { AppState } from './../../core/app.reducer';
import * as selectors from '../../features/orders/store/orders.selectors';
import { OrderCardData } from '@models/orderCardData.model';
import { FirestoreService } from './firestore.service';
import { FirebasePeople, PeopleData } from '@models/people.model';
import { UserData } from '@models/userData.model';

@Injectable({
  providedIn: 'root'
})
export class PickerQrService {

  checkKeyboard: any;
  pluginActive: boolean = true;
  code: string = '';
  focusActive: boolean = true;
  focusActive$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  people$: Observable<PeopleData> = this.store.select(state => state.people);
  peopleSubscription$: Subscription;
  peopleTimerSubscription$: Subscription;
  people: FirebasePeople;

  user$: Observable<UserData> = this.store.select(state => state.user);
  userSubscription$: Subscription;
  user: UserData;

  constructor(
    private utilsService: UtilsService, 
    private store: Store<AppState>, 
    private firestoreService: FirestoreService
    ) {

    this.peopleSubscription$ = this.people$.subscribe({
      next: (people: PeopleData) => {
        if (!people) return;
        this.people = people.responsibleSelected;
      },
      error: (err) => console.log(err),
      complete: () => console.log('complete')

    });

    this.userSubscription$ = this.user$.subscribe(user => {
      this.user = user;
    });
  }

  pickerQrListeners() {
    window.addEventListener("focus", () => {
      this.focusActive = true;
    }, false);

    window.addEventListener("blur", () => {
      this.focusActive = false;
    }, false);
    document.addEventListener('keypress', this.onKeyPress.bind(this), false);
  }

  onKeyPress(event: { key: string; stopImmediatePropagation: () => void; stopPropagation: () => void; }) {
    if(!this.pluginActive || window.document.location.pathname.toLowerCase().includes("login")) return;

    if (Constants.ALLOWED_CHARS.test(event.key)) {
      this.code += event.key
    }

    // Wait for all characters before check
    clearTimeout(this.checkKeyboard)
    this.checkKeyboard = setTimeout(() => {
      this.pluginActive = false;
      this.checkCode(this.code);
    }, Constants.THRESOLD_TIME)
    event.stopImmediatePropagation()
    event.stopPropagation()
  }


  async checkCode(code: any) {
    await this.sleep(100);
    if (!this.validFormatCode(code)) {
      // this.utilsService.showToast('error-scan', `formato no válido!`);
      this.sleep(3000)
      this.clearStatus();
      return;
    }

    let shortCode: Subscription = this.store.select(selectors.getItemById(code))
        .subscribe((order: OrderCardData) => {
          if(!!order && order?.status >= 1 && order?.status <= 2) {
            this.updateStatus(order);
          } else {
            this.utilsService.showToast('error-scan', `Código no válido`);
            this.sleep(3000);
            this.clearStatus();
          };
        });

    shortCode.unsubscribe();
  }

  updateStatus(order: OrderCardData){
    this.utilsService.showToast('success-scan', `${order?.short_code} es válido!`);
    this.firestoreService.updatePickerQr(order, this.people, this.user );
    this.sleep(3000);
    this.clearStatus();
  }

  validFormatCode(code: string | any[]) {
    return code.length == 36;
  }

  clearStatus() {
    this.pluginActive = true;
    this.code = '';
  }

  async sleep(time: number): Promise<any> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, time);
    })
  }
}
