import { createAction, props } from '@ngrx/store';
import { Constants } from '@share/constants';
import { ConfigData, FirebasePeople, Order, OrderCardData, OrderLine, UserData } from '@models';
import { batchItem } from '@models/batchSet.model';


export const fetchOrders = createAction(
    Constants.ACTION_ORDERS_FETCH,
    props<{ user: UserData, config: ConfigData }>()
);

export const errorLoadingData = createAction(
    Constants.ACTION_ORDERS_ERROR
);

export const formatOrders = createAction(
    Constants.ACTION_ORDERS_FORMAT,
    props<{ orders: Order[] }>()
);

export const checkStatusOrders = createAction(
    Constants.ACTION_ORDERS_CHECK_STATUS,
    props<{ orders: OrderCardData[] }>()
);

export const setOrders = createAction(
    Constants.ACTION_ORDERS_SET,
    props<{ orders: Order[] }>()
);

export const updateOrder = createAction(
    Constants.ACTION_ORDERS_UPDATE,
    props<{ order: Order }>()
);

export const updateOrderStatus = createAction(
    Constants.ACTION_ORDERS_STATUS_UPDATE,
    props<{ order: OrderCardData, people: FirebasePeople, user: UserData }>()
);

export const updateOrderFlow = createAction(
    Constants.ACTION_ORDERS_FLOW_UPDATE,
    props<{ order: OrderCardData, action: string, user: UserData, people: FirebasePeople }>()
);

export const updateOrderPhoto = createAction(
    Constants.ACTION_ORDERS_PHOTO_UPDATE,
    props<{ order: OrderCardData }>()
);

export const addBatchItem = createAction(    
    Constants.ACTION_ORDERS_BATCH_ADD_ITEM,
    props<{ batchItem: batchItem, action: string, user: UserData, people: FirebasePeople }>()
);

export const updateBatch = createAction(    
    Constants.ACTION_ORDERS_BATCH_UPDATE,
    props<{ batchesSet: any, action: string, user: UserData, people: FirebasePeople }>()
);

export const updateItemCheck = createAction(
    Constants.ACTION_ORDERS_ITEM_CHECK_UPDATE,
    props<{ order: OrderCardData, item: OrderLine, event: string }>()
);

export const updateTimeState = createAction(
    Constants.ACTION_ORDERS_TIME_STATE_UPDATE,
    props<{ order: OrderCardData, timeState: number }>()
);

export const deleteOrder = createAction(
    Constants.ACTION_ORDERS_DELETE,
    props<{ orderId: string }>()
);

export const createOrder = createAction(
    Constants.ACTION_ORDERS_CREATE,
    props<{ order: Order }>()
);

export const cleanOrders = createAction(
    Constants.ACTION_ORDERS_CLEAN
);

export const addCanceledOrder = createAction(
    Constants.ACTION_ORDERS_ADD_CANCELLED_ORDER,
    props<{ order: OrderCardData }>()
);

export const removeOrders = createAction(
    Constants.ACTION_ORDERS_REMOVE
);