import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { OrderCardData, OrderLine } from '@models/orderCardData.model';
import { StateCard } from '@models/stateCard.model';
import { OrdersStateService, UtilsService } from '@services';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-order-cook',
  templateUrl: './order-cook.component.html',
  styleUrls: ['./order-cook.component.scss'],
})
export class OrderCookComponent {
  @ViewChild('orderCard') card: ElementRef;
  @Input() orderOriginal: OrderCardData;
  @Input() orderCardSet: OrderCardData;
  @Input() header: boolean = true;
  @Input() footer: boolean = true;
  @Input() updateCard: boolean;
  @Output() clickedOrder: EventEmitter<{order: OrderCardData, action: string}> = new EventEmitter();
  @Output() orderTimeState: EventEmitter<{order: OrderCardData, timeState: number}> = new EventEmitter();

  dataCard: StateCard = {};

  lastMilliseconds: string;

  riderTime: string;
  riderMinutes: any;

  scopeTime: number;
  
  constructor(
    public translate: TranslateService,
    private ordersStateService: OrdersStateService,
    public utilsService: UtilsService
  ) { }

  ngOnInit(){
    this.orderState();
  }

  ngOnChanges(changes:SimpleChanges) {
    if(this.checkOrderToRefresh(changes) || !!changes.updateCard){     
      this.orderState();
    } 
  }

  checkDisableButton(){
    return (this.utilsService.netDisabled$.value || this.utilsService.waitingFirestoreResponse$.value.find(orderWaiting => orderWaiting === this.orderOriginal.id));
  }

  trackByItems(item: any ): string {
    return item
  }

  checkOrderToRefresh(changes: SimpleChanges){
    if(!changes.orderOriginal || !changes.orderOriginal.previousValue || !changes.orderOriginal.currentValue) return false;
    const result = _.pickBy(changes.orderOriginal.currentValue, (v, k) => !_.isEqual(changes.orderOriginal.previousValue[k], v));
    return !_.isEmpty(result);
  }

  clickInOrder(){
    if(!!this.orderOriginal.dates.cancelled_at) {
      this.ordersStateService.clickCancelledOrder(this.orderOriginal);
      return;
    }
    this.clickedOrder.emit({order: this.orderOriginal, action: this.dataCard.flowStatus});
  }

  setStrike(item: OrderLine){     
      if(!this.orderOriginal.is_started) return;
      item.is_striked = !item.is_striked;
      return item.is_striked ? 'line-through' : '';
  }
  
  checkRiderStimation(milliseconds: number){
    let timeLeft = (this.orderCardSet?.rider_arriving_at) ? moment(this.orderCardSet?.rider_arriving_at).valueOf() - milliseconds : undefined;
    if(!timeLeft || this.orderCardSet?.rider_arrived) return undefined;
    let minutes = (!!timeLeft && timeLeft > 0) ? moment.duration(timeLeft).minutes() : 1;
    return (minutes > 1) ? minutes : 1;
  }

  checkStrike(item: OrderLine){
      return item.is_striked;
  }

  orderState(){

    const milliseconds: number = this.utilsService.getTimeStamp();
    this.riderMinutes = this.checkRiderStimation(milliseconds);
    this.riderTime = this.ordersStateService.checkRiderTime(this.orderCardSet);

    this.dataCard.classHeader = '';
    this.dataCard.isGrid = true;
    if (!this.header) this.dataCard.classHeader += 'card--break-top';
    if (!this.footer) this.dataCard.classHeader += ' card--break-bottom';
    this.dataCard = this.ordersStateService.checkOrderState(this.orderOriginal, this.dataCard, this.translate.instant('card.finish'));
  }

  logOrder(){
    this.ordersStateService.logOrder(this.orderOriginal);    
  }
}
