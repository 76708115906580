import { createAction, props } from '@ngrx/store';
import { Constants } from '@share/constants';
import { BrandModel } from '@models';

export const fetchStationBrands = createAction(
    Constants.ACTION_STATION_BRANDS_FETCH,
    props<{ brands: BrandModel[] }>()
);

export const updateStationBrands = createAction(
    Constants.ACTION_STATION_BRANDS_UPDATE,
    props<{ brands: any[] }>()
);

export const errorLoadingData = createAction(
    Constants.ACTION_STATION_BRANDS_ERROR
);