/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { createReducer, on } from '@ngrx/store';
import { Order, OrderCardData, OrderLine, TagsModel, UserData } from '@models';
import * as orderActions from './orders.actions';
import * as moment from 'moment';
import { OrdersGrid } from '@models/ordersGrid.model';
import { Constants } from '@share/constants';

export const initialState: OrdersGrid = { ordersCardData: null, ordersStatusReview: [] };

export const ordersReducer = createReducer(initialState,
  on(orderActions.setOrders, (state, { orders }) => setOrders(state, orders)),
  on(orderActions.removeOrders, (state) => cleanOrders()),
  on(orderActions.updateTimeState, (state, { order, timeState }) => updateTimeState(state, order, timeState)),
  on(orderActions.addCanceledOrder, (state, { order }) => cancelledOrder(state, order)),
  on(orderActions.errorLoadingData, (state) => state)
);

function setOrders(state: OrdersGrid, orders: Order[]) {
  const user: UserData = JSON.parse(localStorage.getItem('user'));
  const ordersCancelledChecked: string[] = JSON.parse(localStorage.getItem('cancelledOrders')) || [];
  const newState: OrdersGrid = { ...state };
  if (state.ordersCardData) { newState.ordersCardData = [...state.ordersCardData]; }
  if (!user) { return newState; }
  const is360Station: boolean = user.type === Constants.STATION_TYPE_COOK || user.type === Constants.STATION_TYPE_PREP || user.type === Constants.STATION_TYPE_PACK;
  newState.ordersStatusReview = [];
  const OrdersData = orders.map((orderPure: Order) => {

    const orderPureCopy = { ...orderPure };

    if(!!orderPureCopy.dates.timestamp_3 && orderPureCopy.status < 3) { orderPureCopy.status = 3; }

    let order: any = { ...orderPureCopy };
    let auxOrderLines: OrderLine[] = [];

    //Chequeamos si la orden está cancelada y revisada para asignarle un status local.
    // En estaciones de flow la eliminamos del array
    if ((!!order.dates.cancelled_at && !!ordersCancelledChecked.find(orderId => orderId === order.id))
      || (!!order.dates.cancelled_at && (user.type === Constants.STATION_TYPE_LITE && order.status >= 2 || user.type === Constants.STATION_TYPE_SITE && order.status >= 3 || user.type === Constants.STATION_TYPE_HIGH_DEMAND && order.status <= 0))) { order.status = 9; }
    if (order.status === 9 && is360Station) { return order; }
    //Transformamos los order lines y los modificadores en arrays
    order = remakeOrderLines(order, user, is360Station);


    const statusCheck = checkOrderStatus(order);

    if (statusCheck) { newState.ordersStatusReview.push(order); }

    //asignamos revisamos si existe la propiedad own_image y si no la creamos a false
    // order.own_image = order.own_image || false;

    //Ordenamos los order lines
    order.order_lines = sortItems(order.order_lines);

    //Filtramos los modificadores y nos quedamos con los que pertenecen al flow y a los tags de la estación en la variable auxiliar
    auxOrderLines = checkModifiers(order.order_lines, auxOrderLines, order.station_tags, order.station_type);

    // console.log("auxOrderLines", auxOrderLines);
    

    // //Seteamos la variable de order lines auxiliar
    // const settedAuxLineOrders = auxOrderLines.map(({ modifiers, ...keepAttrs }) => keepAttrs);

    // //Actualizamos los order lines de la order
    // order.order_lines = settedAuxLineOrders;

    order.order_lines = auxOrderLines;

    // console.log("order.order_lines", order.order_lines);
    

    //Añadimos propiedad de si la comanda ha empezado en esa estación,
    //y si existe esa fecha en propiedad genérica
    // console.log("check started", order.order_lines.filter(order_line => order_line.product.flow.find(step => !step.started_at)));
    const notStarted = order.order_lines.filter((order_line: { product: { flow: any[] } }) => order_line.product.flow.find((step: { started_at: any }) => !step.started_at));
    const checkStep = order.order_lines.find((order_line: { product: { flow: any[] } }) => order_line.product.flow.find((step: { started_at: any }) => step.started_at));
    order.is_started = (user.type == Constants.STATION_TYPE_LITE && order.status == 1) ? true : !!checkStep && notStarted.length <= 0;
    if (!!checkStep) {
      order.time_started = checkStep.product.flow[0].started_at;
      // order.time_started = order.dates.timestamp_0;
    }


    //Añadimos propiedad de si la comanda ha terminado en esa estación
    const notFinished = order.order_lines.filter((order_line: { product: { flow: any[] } }) => order_line.product.flow.find((step: { finished_at: any }) => !step.finished_at));
    order.is_finished = notFinished.length <= 0;

    //Añadimos propiedad de alta prioridad más baja para la order
    order.sort_high_priority = Math.min(...order.order_lines.map((orderLine: { product: { priority: any } }) => orderLine?.product?.priority));

    order.finish_time_estimated = (moment(order.dates.scheduled_at).valueOf() + order.expected_duration);
    order.rider_time_estimated = (moment(order.dates.created_at).valueOf() + order.expected_duration);

    const cleanedOrderPure = { ...orderPureCopy };
    delete cleanedOrderPure.change_type;
    order.firebaseOrder = cleanedOrderPure;

    return order;
  });

  let settedOrdersCardData: OrderCardData[] = [...OrdersData.filter(order => order.order_lines.length)];
  //Filtramos las orders que hay que mostrar en la estación descartando las finalizadas y las que puedan no tener datos relativos a la estación (si las hubiera)

  if (state.ordersCardData) {
    newState.ordersCardData = reduceOrders(settedOrdersCardData, newState.ordersCardData, is360Station);
    newState.ordersCardData = sortByPriority(newState.ordersCardData, user, is360Station);
    newState.ordersCardData = addIndex(newState.ordersCardData);
    // if (is360Station) {
    //     newState.ordersCardData = addIndex(newState.ordersCardData);
    // }
  } else {

    if (is360Station) {
      settedOrdersCardData = OrdersData.filter(order => order.order_lines.length > 0 && !order.is_finished);
    }

    settedOrdersCardData = sortByPriority(settedOrdersCardData, user, is360Station);
    settedOrdersCardData.forEach((cardData, index) => {
      delete cardData.change_type;
      cardData.index = index;
      return cardData;
    });
    newState.ordersCardData = settedOrdersCardData;
  }

  if (!is360Station) {
    const ordersBundled = newState.ordersCardData.filter(myOrders => myOrders.bundled_with_multi);
    const resetOrders = JSON.parse(JSON.stringify([...newState.ordersCardData]));
    if (ordersBundled.length > 1) {

      ordersBundled.forEach(orderBundled => {
        const pickCodesToFind = orderBundled.bundled_with_multi.filter((code: string) => code !== orderBundled.platform_pickup_code);
        const ordersMatch = ordersBundled.filter((order) => pickCodesToFind.find((code: string) => code === order.platform_pickup_code));

        if (ordersMatch.length > 0) {
            const orderMatch = ordersMatch.find(orderMatch => orderMatch.bundled_with_multi.find( code => code === orderBundled.platform_pickup_code));
            if(orderMatch){
              const indexOrderMatch = resetOrders.findIndex(orderReset => orderReset.platform_pickup_code === orderBundled.platform_pickup_code );
              resetOrders[indexOrderMatch].bundled_code = orderMatch.short_code;
            }
          }
        }
      );
    } else {
      resetOrders.forEach((order: { bundled_code: any }, index: string | number) => {
        if (!!order.bundled_code) {
          delete resetOrders[index].bundled_code;
        }
      });
    };
    newState.ordersCardData = resetOrders;
  }

  return newState;
}

function reduceOrders(settedOrdersCardData: OrderCardData[], ordersCardData: OrderCardData[], is360Station: boolean) {
  settedOrdersCardData.forEach(orderUpdate => {

    if (!ordersCardData.find(oldOrder => oldOrder.id === orderUpdate.id) && orderUpdate.change_type === 'modified' && !orderUpdate.kds_source) {
      delete orderUpdate.change_type;
      ordersCardData.push(orderUpdate);
      return;
    }

    switch (orderUpdate.change_type) {
      case 'added':
        delete orderUpdate.change_type;
        ordersCardData.push(orderUpdate);
        break;

      case 'modified':
        ordersCardData.forEach((oldOrder, index) => {
          if (is360Station && oldOrder.id === orderUpdate.id && orderUpdate.is_finished) {
            ordersCardData.splice(index, 1);
          } else if (oldOrder.id === orderUpdate.id) {
            delete orderUpdate.change_type;
            ordersCardData[index] = orderUpdate;
          }
        });
        break;

      case 'removed':
        ordersCardData.forEach((oldOrder, index) => {
          if (oldOrder.id === orderUpdate.id) {
            delete orderUpdate.change_type;
            ordersCardData.splice(index, 1);
          }
        });
        break;

      default:
        break;
    }
  });

  return ordersCardData;
}

function addIndex(ordersCardData: OrderCardData[]) {
  const indexedArr = [];
  ordersCardData.forEach((cardData, index) => {
    const newObj = { ...cardData };
    newObj.index = index;
    indexedArr.push(newObj);
  });
  return indexedArr;
}

function remakeOrderLines(order: OrderCardData, user: UserData, is360Station: boolean) {
  const settedOrder: OrderCardData = JSON.parse(JSON.stringify({ ...order }));
  settedOrder.all_flows = [];
  settedOrder.short_id = settedOrder.id.slice(-7);
  settedOrder.station_type = user.type;
  settedOrder.station_tags = user.tags;
  settedOrder.site_is_360 = user.site.is_360;
  settedOrder.order_lines = Object.entries(settedOrder.order_lines).map(([keyOrder, valueOrder]: any) => {
    if(valueOrder.product.tags.some((tag: string) => tag === 'no_show')) {
      delete settedOrder.order_lines[keyOrder];
      return;
    }
    valueOrder.internal_id = keyOrder;
    valueOrder.product.flow = Object.entries(valueOrder.product.flow).map(([keyFlow, valueFlow]: any) => {

      const valueFlowCopy = { ...valueFlow };
      // console.log("valueFlowCopy", valueFlowCopy);
      
      valueFlowCopy.tags = valueOrder.product.tags;
      valueFlowCopy.item_number = valueOrder.item_number;
      valueFlowCopy.parent_item_number = valueOrder.parent_item_number;
      valueFlowCopy.is_modifier = valueOrder.is_modifier;
      valueFlowCopy.is_combo = valueOrder.is_combo;
      valueFlowCopy.product_name = valueOrder.product.kitchen_description || valueOrder.product.description;
      settedOrder.all_flows.push(valueFlowCopy);

      if (is360Station && valueFlow.name !== user.type) { return; }
      valueFlow.internal_id = keyFlow;
      return valueFlow;
    });

    valueOrder.product.flow = valueOrder.product.flow.filter((step: any) => step);

    valueOrder.modifiers = Object.entries(valueOrder.modifiers).map(([keyModifier, valueModifier]: any) => {
      if(valueModifier.product.tags.some((tag: string) => tag === 'no_show')) {
        delete valueOrder.modifiers[keyModifier];
        return;
      }
      valueModifier.internal_id = keyModifier;
      if (valueModifier.product.tags.length === 0) { valueModifier.product.tags = valueOrder.product.tags; }
      valueModifier.product.flow = Object.entries(valueModifier.product.flow).map(([keyModifierFlow, valueModifierFlow]: any) => {
        const valueModifierFlowCopy = { ...valueModifierFlow };
        valueModifierFlowCopy.tags = valueModifier.product.tags;
        valueModifierFlowCopy.item_number = valueModifier.item_number;
        valueModifierFlowCopy.parent_item_number = valueModifier.parent_item_number;
        valueModifierFlowCopy.is_modifier = valueModifier.is_modifier;
        valueModifierFlowCopy.is_combo = valueModifier.is_combo;
        valueModifierFlowCopy.product_name = valueModifier.product.kitchen_description || valueModifier.product.description;
        settedOrder.all_flows.push(valueModifierFlowCopy);

        if (is360Station && valueModifierFlow.name !== user.type) { return; }
        valueModifierFlow.internal_id = keyModifierFlow;
        return valueModifierFlow;
      });
      valueModifier.product.flow = valueModifier.product.flow.filter((step: any) => step);
      return valueModifier;
    });

    // console.log("valueOrder modifiers", valueOrder.modifiers);

    valueOrder.modifiers = valueOrder.modifiers.filter((modifier: any) => modifier);
    // console.log("valueOrder", valueOrder);
    
    return valueOrder;
  });

  settedOrder.order_lines = settedOrder.order_lines.filter((orderLine: any) => orderLine);

  if(user.type === Constants.STATION_TYPE_PACK) {
    settedOrder.order_lines = settedOrder.order_lines.filter((orderLine: any) => !orderLine.is_combo);
  }

  return settedOrder;
}

function sortItems(orderLines: OrderLine[]) {
  orderLines.sort((a: OrderLine, b: OrderLine) => {
    if (a.item_number > b.item_number) {
      return 1;
    }
    if (a.item_number < b.item_number) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });

  return orderLines;
}

function sortByPriority(orders: OrderCardData[], user: UserData, is360Station: boolean) {

  // Sort in site and runner stations to show finished orders at the end of the list
  if (!is360Station) {
    orders.sort((a: OrderCardData, b: OrderCardData) => (a.sort_high_priority > b.sort_high_priority) ? 1 : (a.sort_high_priority < b.sort_high_priority) ? -1 : 0);
    orders.sort((a: OrderCardData, b: OrderCardData) => (a.status < b.status) ? 1 : (a.status > b.status) ? -1 : 0);
    if (user.type === Constants.STATION_TYPE_SITE || user.type === Constants.STATION_TYPE_RUNNER) { orders.sort((a: OrderCardData, b: OrderCardData) => (b.status >= 3 || a.status === 2) ? -1 : 1); }
    if (user.type === Constants.STATION_TYPE_LITE) { orders.sort((a: OrderCardData, b: OrderCardData) => (b.status >= 2 || a.status === 2) ? -1 : 1); }
    orders.sort((a: OrderCardData, b: OrderCardData) => ((a.status >= 3 && b.status >= 3) && (a.dates.timestamp_3 > b.dates.timestamp_3)) ? -1 : 1);
    orders.sort((a: OrderCardData, b: OrderCardData) => ((a.status == 2 && b.status == 2) && (a.rider_here)) ? -1 : 1);
    orders.sort((a: OrderCardData, b: OrderCardData) => (b.dates.cancelled_at && b.status !== 9 && a.status >= 3) ? 1 : (a.dates.cancelled_at) ? -1 : 0);
    orders.sort((a: OrderCardData, b: OrderCardData) => (b.status == 9) ? -1 : 0);
    orders.sort((a: OrderCardData, b: OrderCardData) => ((a.status == 9 && b.status == 9) && (a.dates.cancelled_at > b.dates.cancelled_at)) ? -1 : 0);
  } else {
    let ordersStarted = JSON.parse(JSON.stringify(orders.filter(order => order.is_started)));
    let ordersNotStarted = JSON.parse(JSON.stringify(orders.filter(order => !order.is_started)));

    ordersNotStarted.sort((a: OrderCardData, b: OrderCardData) => (a.sort_high_priority > b.sort_high_priority) ? 1 : (a.sort_high_priority < b.sort_high_priority) ? -1 : 0);

    ordersStarted.sort((a: OrderCardData, b: OrderCardData) => (a.time_started && b.time_started && a.time_started > b.time_started) ? 1 : -1);

    orders = [...ordersStarted, ...ordersNotStarted];


    // Sort to show orders by priority
    // orders.sort((a: OrderCardData, b: OrderCardData) => (a.sort_high_priority > b.sort_high_priority) ? 1 : (a.sort_high_priority < b.sort_high_priority) ? -1 : 0);
    // Sort to show started orders first
    // orders.sort((a: OrderCardData, b: OrderCardData) => (a.is_started === b.is_started) ? 0 : a.is_started ? -1 : 1);


    // orders.sort((a: OrderCardData, b: OrderCardData) => 
    //   //check if a and b has time_started, and then order by time_started
    //   (a.time_started && b.time_started && a.time_started < b.time_started) ? 1 : 0
    // );
  }

  return orders;
}

function checkModifiers(orderLines: OrderLine[], auxOrderLines: OrderLine[], stationTags: TagsModel[], stationType: string) {
  orderLines.forEach((orderLine: OrderLine) => {
    const auxLines: OrderLine[] = [];
    auxLines.push(orderLine);

    orderLine.modifiers = sortItems(orderLine.modifiers);

    orderLine.modifiers.forEach((modifier: OrderLine) => {
      if (stationType === Constants.STATION_TYPE_RUNNER || stationType === Constants.STATION_TYPE_SITE || stationType === Constants.STATION_TYPE_LITE || stationType === Constants.STATION_TYPE_PACK) {
        auxLines.push(modifier);
      } else {
        // if (!modifier.product.flow || modifier.product.flow.length <= 0) return;
        const checkTagModifier = modifier.product.tags.find(tag => stationTags.find(stationTag => stationTag?.name === tag));
        if (checkTagModifier || modifier.product.tags.length == 0) {
          auxLines.push(modifier);
        }
      }
    });

    if (
      (stationType === Constants.STATION_TYPE_RUNNER
        || stationType === Constants.STATION_TYPE_SITE
        || stationType === Constants.STATION_TYPE_LITE
        || stationType === Constants.STATION_TYPE_PACK)

      || (auxLines.length > 1
        || (!auxLines[0].product.tags.length)
        || (auxLines.length === 1 && auxLines[0].product.flow.length > 0
          && auxLines[0].product.tags.find(tag => stationTags.find(stationTag => stationTag?.name === tag))))
    ) {
      auxOrderLines = [...auxOrderLines, ...auxLines];
    }
  });
  if (auxOrderLines.length === 1 && auxOrderLines[0].is_combo) { auxOrderLines = []; }
  return auxOrderLines;
}

function checkOrderStatus(order: any) {
  const ordersFiltered = order.all_flows.filter((flow: { finished_at: any }) => flow.finished_at);

  return order.all_flows.length !== 0 && (order.all_flows.length === ordersFiltered.length);
}

function cleanOrders() {

  const cleanData: OrdersGrid = { ordersCardData: null, ordersStatusReview: [] };
  return cleanData;
}

function updateTimeState(state: OrdersGrid, order: OrderCardData, timeState: number) {
  const newState: OrdersGrid = JSON.parse(JSON.stringify({ ...state }));

  newState.ordersCardData.forEach(orderCard => {
    if (orderCard.id === order.id) {
      orderCard.time_state = timeState;
    }
    return orderCard;
  });
  return newState;
}

function cancelledOrder(state: OrdersGrid, order: OrderCardData) {
  const newState: OrdersGrid = JSON.parse(JSON.stringify({ ...state }));
  const user: UserData = JSON.parse(localStorage.getItem('user'));
  const is360Station: boolean = user.type === Constants.STATION_TYPE_COOK || user.type === Constants.STATION_TYPE_PREP || user.type === Constants.STATION_TYPE_PACK;

  if (user.type === Constants.STATION_TYPE_COOK || user.type === Constants.STATION_TYPE_PREP || user.type === Constants.STATION_TYPE_PACK) {
    newState.ordersCardData = newState.ordersCardData.filter(orderArr => orderArr.id !== order.id);
  } else {
    newState.ordersCardData.forEach((orderCard) => {
      if (orderCard.id === order.id) {
        orderCard.status = 9;
      }
      return orderCard;
    });
  }

  newState.ordersCardData = sortByPriority(newState.ordersCardData, user, is360Station);
  return newState;
}
