import { FirestoreService } from "./firestore.service";
export { FirestoreService } from "./firestore.service";

import { OrdersStateService } from "./orders-state.service";
export { OrdersStateService } from "./orders-state.service";

import { UserService } from "./user.service";
export { UserService } from "./user.service";

import { UtilsService } from "./utils.service";
export { UtilsService } from "./utils.service";

import { PickerQrService } from "./picker-qr.service";
export { PickerQrService } from "./picker-qr.service";

import { ProxyService } from "./proxy.service";
export { ProxyService } from "./proxy.service";

import { StationBrandsService } from "./station-brands.service";
export { StationBrandsService } from "./station-brands.service";

import { WarehouseItemsService } from "./warehouse-items.service";
export { WarehouseItemsService } from "./warehouse-items.service";

export const Services: any[] = [
    FirestoreService,
    UserService,
    UtilsService,
    OrdersStateService,
    ProxyService,
    PickerQrService,
    StationBrandsService,
    WarehouseItemsService
];
