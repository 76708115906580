import { Injectable } from '@angular/core';
import { OrderCardData } from '@models/orderCardData.model';
import { StateCard } from '@models/stateCard.model';
import { Constants } from '@share/constants';
import * as moment from 'moment';

import { Store } from '@ngrx/store';
import { AppState } from '../../core/app.reducer';
import * as ordersActions from './../../features/orders/store/orders.actions';
import * as statsActions from './../../features/stats/store/stats.actions';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { StatsData } from '@models/stats.model';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from './utils.service';


@Injectable({
  providedIn: 'root'
})
export class OrdersStateService {

  stats$: Observable<StatsData> = this.store.select(state => state.stats);
  statsSubscription$: Subscription;
  stats: StatsData;

  constructor(private store: Store<AppState>, private translate: TranslateService, private utilsService: UtilsService) {
    this.statsSubscription$ = this.stats$.subscribe(stats => {
      this.stats = stats;
    });
  }

  checkOrderState(order: OrderCardData, dataCard: StateCard, ctaText: string) {

    const nowTime: number = this.utilsService.getTimeStamp();

    const diffDates = moment(nowTime).diff(moment(order.dates.created_at));
    dataCard.acceptedTime = moment(diffDates).format('mm:ss');

    if (order.flow_empty) {
      dataCard.acceptedTime = '00:00';
      dataCard.ctaText = 'Error en Flow';
      dataCard.classBtn = 'button--outline disabled';
      dataCard.classHeader += ' card--empty-flow';
      dataCard.classHeader += (dataCard.isGrid) ? ' card--empty-flow' : ' card-list--empty-flow';
      dataCard.state = Constants.ORDER_STATUS_EMPTY_FLOW;
    } else if (!!order.dates.cancelled_at) {
      dataCard.startTime = 'Cancel';
      dataCard.ctaText = (order.status !== 9) ? this.translate.instant('card.close') : this.translate.instant('card.cancelled');
      dataCard.classBtn = 'button--secondary';
      dataCard.classHeader += ' card';
      if (!dataCard.isGrid) { dataCard.classHeader += '-list'; }
      dataCard.classHeader += (order.status !== 9) ? '--cancel' : '--cancelled';
    } else {
      dataCard.ctaText = (this.utilsService.waitingFirestoreResponse$.value.find(orderWaiting => orderWaiting === order.id)) ? 'Enviando...' : (order.is_started) ? ctaText : 'Comenzar';
      dataCard.flowStatus = (order.is_started) ? Constants.FLOW_FINISH : Constants.FLOW_START;
      dataCard.classBtn = (order.is_started) ? 'button--primary' : 'button--secondary';

      let time = moment(order.sort_high_priority).diff(nowTime);
      if (order.station_type === 'pack') {
        time = moment(nowTime).diff(moment(order.dates.created_at));
        dataCard.startTime = (time >= 0) ? moment(time).format('mm:ss') : undefined;
      } else {
        if (order.is_started) { time = moment(nowTime).diff(moment(order.time_started)); }
        dataCard.startTime = (time >= 0) ? moment(time).format('mm:ss') : undefined;
      }

      const difference: number = (order?.finish_time_estimated - nowTime) / 1000;

      dataCard.classHeader += ' card';
      if (!dataCard.isGrid) { dataCard.classHeader += '-list'; }

      switch (true) {
        case (dataCard.startTime && order.station_type !== 'pack') && !order.is_started:
          break;
        case difference > Constants.WARNING_STATUS_TIME:
          dataCard.classHeader += (order.is_started) ? '--active-success' : '--disabled-success';
          break;
        case difference > 0 && difference < Constants.WARNING_STATUS_TIME:
          dataCard.classHeader += (order.is_started) ? '--active-warning' : '--disabled-warning';
          break;
        default:
          this.checkDelayOrder(order.id);
          dataCard.classHeader += (order.is_started) ? '--active-alert' : '--disabled-alert';
          break;
      }
    }
    return dataCard;
  }

  logOrder(order: OrderCardData) {
    console.log('Order Clicked!!', order);
  }

  checkDelayOrder(orderId: string, removeAction: boolean = false) {
    const existOrder = this.stats.delayOrders.filter(delayOrderId => delayOrderId === orderId);
    if (removeAction && existOrder.length === 1) {
      this.store.dispatch(statsActions.removeDelayOrder({ orderId }));
    } else if (!removeAction && existOrder.length === 0) {
      this.store.dispatch(statsActions.addDelayOrder({ orderId }));
    }
  }

  checkRiderTime(order: OrderCardData) {
    switch (true) {
      case order.status == 9:
        return '--';
      case order.status >= 3 && !!order.dates.rider_at:
        return `${moment(order.dates.rider_at).format('HH:mm')}h.`;
      case order.status >= 3 && !!order.rider_arriving_at:
        return `${moment(order.rider_arriving_at).format('HH:mm')}h.`;
      case order.status >= 3:
        return '--';
      case !!order.dates.rider_at:
        return moment(order.dates.rider_at).locale(moment.locale('es')).fromNow();
      case !!order.rider_arriving_at: 
        return moment(order.rider_arriving_at).locale(moment.locale('es')).fromNow()
        .replace('minutos', ' min')
        .replace('horas', ' h')
        .replace('hace unos segundos', 'Llegó ahora')
        .replace('una', ' 1');
      default:
        return (moment(order.rider_time_estimated).locale(moment.locale('es')).fromNow()) + ' (aprox.)';
    }
  }

  clickCancelledOrder(order: OrderCardData) {
    let ordersCancelledChecked: string[] = JSON.parse(localStorage.getItem('cancelledOrders')) || [];
    ordersCancelledChecked.push(order.id);
    localStorage.setItem('cancelledOrders', JSON.stringify(ordersCancelledChecked));
    this.store.dispatch(ordersActions.addCanceledOrder({ order }));
  }
}
