import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CalculatorItem } from '@models/calculatorItem.model';

@Component({
  selector: 'app-calculator-hd',
  templateUrl: './calculator-hd.component.html',
  styleUrls: ['./calculator-hd.component.scss'],
})
export class CalculatorHdComponent implements OnInit {

  @Input() showingCalculator: boolean | null = null;
  @Input() mainCalculatorItems: CalculatorItem[] = [];

  previousCalculatorItems: { size: number }[] = [];

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.mainCalculatorItems) {
      this.mainCalculatorItems = this.mainCalculatorItems.map((newItem, index) => {
        const previousItem = this.previousCalculatorItems[index];
        let change = 'equal';

        if (previousItem) {
          if (newItem.size > previousItem.size) {
            change = 'up';
          } else if (newItem.size < previousItem.size) {
            change = 'down';
          }
        }

        return { ...newItem, change };
      });

      this.previousCalculatorItems = this.mainCalculatorItems.map(item => ({ size: item.size }));

      this.mainCalculatorItems.forEach((item, index) => {
        if (item.change !== 'equal') {
          setTimeout(() => {
            this.mainCalculatorItems[index].change = 'equal';
          }, 5000);
        }
      });
    }
  }

  calculateUnits(units: number) {
    return Math.round(units);
  }

}
