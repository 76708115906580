import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, switchMap, map, exhaustMap, mergeMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { FirestoreService, UtilsService } from '@services';

import * as actions from './sites.actions';
import { FirebaseSite } from '@models/sites.model';


 
@Injectable()
export class SitesEffects {
  
  setSites$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.fetchSites),
      switchMap(() => 
        this.firestoreService.getSites().pipe(
          map((sites: FirebaseSite[]) => { 
            this.utilsService.showSitesModal();
            return actions.setSites({sites})
            }),
          catchError((err) => {
            console.log("error", err);
            return of(actions.errorLoadingData());
          })
        )
      )
    ) 
  });

  constructor(
    private actions$: Actions,
    private firestoreService: FirestoreService,
    private utilsService: UtilsService
  ) {}
}