import { createAction, props } from '@ngrx/store';
import { Constants } from '@share/constants';
import { ConfigData, FirebasePeople, UserData } from '@models';


export const fetchPeople = createAction(
    Constants.ACTION_PEOPLE_FETCH,
    props<{ user: UserData, config: ConfigData }>()
);

export const setPeople = createAction(
    Constants.ACTION_PEOPLE_SET,
    props<{ people: FirebasePeople[] }>()
);

export const selectPeople = createAction(
    Constants.ACTION_PEOPLE_SELECT,
    props<{ responsible: FirebasePeople }>()
);

export const fetchResponsibleSelected = createAction(
    Constants.ACTION_RESPONSIBLE_SELECTED,
    props<{ user: UserData }>()
);

export const updateResponsible = createAction(
    Constants.ACTION_RESPONSIBLE_UPDATE,
    props<{ station_id: number, responsible: FirebasePeople }>()
);

// export const setTotalOrders = createAction(
//     Constants.ACTION_PEOPLE_SET_TOTAL_ORDERS,
//     props<{ orders: number }>()
// );

// export const setTotalRiders = createAction(
//     Constants.ACTION_PEOPLE_SET_TOTAL_RIDERS,
//     props<{ riders: number }>()
// );

// export const addDelayOrder = createAction(
//     Constants.ACTION_PEOPLE_ADD_DELAY_ORDER,
//     props<{ orderId: string }>()
// );

// export const removeDelayOrder = createAction(
//     Constants.ACTION_PEOPLE_REMOVE_DELAY_ORDER,
//     props<{ orderId: string }>()
// );

// export const checkDelayOrders = createAction(
//     Constants.ACTION_PEOPLE_CHECK_DELAY_ORDERS,
//     props<{ orders: OrderCardData[] }>()
// );

export const cleanPeople = createAction(
    Constants.ACTION_PEOPLE_CLEAN
);

export const errorLoadingData = createAction(
    Constants.ACTION_ORDERS_ERROR
);