import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, switchMap, map, exhaustMap, mergeMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { FirestoreService } from '@services';

import * as actions from './people.actions';


 
@Injectable()
export class PeopleEffects {
  
  setPeople$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.fetchPeople),
      switchMap((data) => 
        this.firestoreService.getPeople(data.user, data.config).pipe(
          map((people) => actions.setPeople({people})),
          catchError((err) => {
            console.log("error", err);
            return of(actions.errorLoadingData());
          })
        )
      )
    ) 
  });

  setResponsibleSelected$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.fetchResponsibleSelected),
      switchMap((data) => {

        return this.firestoreService.getResponsibleSelected(data.user).pipe(
          map((responsible) => {

            return actions.selectPeople({responsible})}),
          catchError((err) => {
            console.log("error", err);
            return of(actions.errorLoadingData());
          })
        )
        })
    ) 
  });

  updateResponsibleSelected$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.updateResponsible),
      exhaustMap((data => this.firestoreService.updateResponsibleSelected(data.station_id, data.responsible)
        .pipe(
          mergeMap(() => {
            return []
          }),
          catchError((err) => {
            console.log("error", err);
            return of(actions.errorLoadingData());
          })
        )
      ))
    )
  });

  constructor(
    private actions$: Actions,
    private firestoreService: FirestoreService
  ) {}
}