import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateOrderLimit'
})
export class DateOrderLimitPipe implements PipeTransform {

  transform(values: number): string {
    const clock = moment(values).format('HH:mm');
    return clock;
  }

}
