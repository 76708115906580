import { Pipe, PipeTransform } from '@angular/core';
import { parseInt } from 'lodash';

@Pipe({
  name: 'parseAmount'
})
export class ParseAmountPipe implements PipeTransform {

  transform(amount: string, currency: string): string {
    switch (amount) {
      case null:
      case '0':
        return '--'
    
      default:
        return `${parseInt(amount)/100}${currency}`;
    }
    
  }

}
