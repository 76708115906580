import { ConfigData } from '@models/config.model';
import { createAction, props } from '@ngrx/store';
import { Constants } from '@share/constants';


export const fetchConfig = createAction(
    Constants.ACTION_CONFIG_FETCH,
    props<{ site: string }>()
);

export const setConfig = createAction(
    Constants.ACTION_CONFIG_SET,
    props<{ config: ConfigData }>()
);

export const cleanConfig = createAction(
    Constants.ACTION_CONFIG_CLEAN
);

export const errorLoadingData = createAction(
    Constants.ACTION_CONFIG_ERROR
);
