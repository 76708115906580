
import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { OrderCardData, OrderLine } from '@models/orderCardData.model';
import { OrdersStateService, UserService, UtilsService } from '@services';
import { Constants } from '@share/constants';
import { TranslateService } from '@ngx-translate/core';

import * as moment from 'moment';
import * as _ from 'lodash';
import { ModalController } from '@ionic/angular';
import { ChecklistItemsComponent } from '../index';
import { UserData } from '@models/userData.model';
import { ConfigData } from '@models/config.model';

@Component({
  selector: 'app-order-lite',
  templateUrl: './order-lite.component.html',
  styleUrls: ['./order-lite.component.scss'],
})
export class OrderLiteComponent {
  @Input() configuration: ConfigData;
  @Input() order: OrderCardData;
  @Input() dropDownActive: string;
  @Input() updateCard: boolean;
  @Input() user: UserData = null;
  @Output() dropDownState: EventEmitter<any> = new EventEmitter();
  @Output() clickedOrder: EventEmitter<{order: OrderCardData; action: string}> = new EventEmitter();
  @Output() orderTimeState: EventEmitter<{order: OrderCardData; timeState: number}> = new EventEmitter();

  lastMilliseconds: string;
  scopeTime: number;

  ctaText: string;
  btnText: string;
  classBtn: string;
  classHeader: string;
  flowStatus: string;

  startTime: string;

  statusTime: string;
  timerMinutes: number;
  timerSeconds: number;

  riderTime: string;
  riderMinutes: any;
  dispatchTime: string;

  constructor(
    public translate: TranslateService,
    private modalController: ModalController,
    private ordersStateService: OrdersStateService,
    private userService: UserService,
    public utilsService: UtilsService
  ) {}

  get platformName(){
    let classBase = 'platform__';
    // switch (this.order?.delivery_platform?.name.toLowerCase()) {
    switch (this.order?.source.toLowerCase()) {
      case 'glovo':
        classBase += 'glovo';
        break;
      case 'uber':
        classBase += 'uber-eats';
        break;
      case 'flipdish':
        classBase += 'flipdish';
        break;
      case 'justeat':
        classBase += 'just-eat';
        break;

      default:
        classBase += 'stuart';
        break;
    }
    return classBase;
  }

  ngOnInit(){
    this.checkOrderStatus();
  }

  ngOnChanges(changes:SimpleChanges) {
    if(this.checkOrderToRefresh(changes) || !!changes.updateCard){     
      this.checkOrderStatus();
    } 
  }

  checkDisableButton(){
    return (this.utilsService.netDisabled$.value || this.utilsService.waitingFirestoreResponse$.value.find(orderWaiting => orderWaiting === this.order.id));
  }

  async checkListItems(){
    const settedOrder = this.order.order_lines.map((orderLine: OrderLine) => {
      const setQuantityChecked = (orderLine.is_combo) ? orderLine.qty : (!!orderLine.qty_checks) ? orderLine.qty_checks : 0;
      return {...orderLine, qty_checks: setQuantityChecked};
    })
    
    const hasCamera = (this.configuration.camera_station && this.configuration.camera_station.length) ? this.configuration.camera_station.includes(this.user.id as number) : false;
    const modalClass = hasCamera ? 'custom-modal-camera' : 'custom-modal';
    // const modalClass = 'custom-modal';
    const modal =  await this.modalController.create({
      component: ChecklistItemsComponent,
      cssClass: modalClass,
      backdropDismiss: true,
      componentProps: { 
        order: {...this.order, order_lines: settedOrder },
        cameraAvailable: hasCamera
      }
    });

    await modal.present();

    modal.onDidDismiss().then((response) => {
      if(!!response.data.action && response.data.action === 'finish'){
        this.clickedOrder.emit({order: response.data.order, action: this.flowStatus});
      } else{
        this.order = {...this.order, order_lines: response.data.order.order_lines};
      }
    })
  }

  checkOrderToRefresh(changes: SimpleChanges){
    if(!changes.order || !changes.order.previousValue) return false;
    const result = _.pickBy(changes.order.currentValue, (v, k) => !_.isEqual(changes.order.previousValue[k], v))
      return !_.isEmpty(result);
  }

  clickInOrder(){
    if(this.order.dates?.cancelled_at) {
      this.ordersStateService.clickCancelledOrder(this.order);
      return;
    }
    
    if(this.order.status === 1 && this.user.site.short_name !== 'MUR'){
      this.checkListItems();
    }else{
      this.clickedOrder.emit({order: this.order, action: this.flowStatus});
    }    
  }

  toggleDropdown(e: any) {
    this.ordersStateService.logOrder(this.order);
    if(e.target?.dataset?.name === 'buttonEvent') {
      e.preventDefault();
      e.stopPropagation();
    }else{
      this.dropDownState.emit(this.order.id);
    }
  }

  checkOrderStatus(){

    const milliseconds: number = this.utilsService.getTimeStamp();
    this.classHeader = '';

    this.riderMinutes = this.checkRiderStimation(milliseconds);

    this.riderTime = this.ordersStateService.checkRiderTime(this.order);

    let time = moment(milliseconds).diff(moment(this.order.dates.created_at));

    this.startTime = moment(this.order.dates.created_at).locale(moment.locale('es')).fromNow(true);

    switch (true) {
      case this.order.flow_empty:
        this.statusTime = '00:00';
        this.ctaText = this.translate.instant('card.flow_error');
        this.classBtn = 'button--outline';
        this.classHeader += ' card-site--empty-flow';
        return;
      case !!this.order.dates.cancelled_at:
        this.startTime = '--';
        this.riderTime = '--';
        this.dispatchTime = '--'
        this.statusTime = (this.order.status <= 1) ? this.translate.instant('card.cancelled').toUpperCase() : (moment(this.order.dates.cancelled_at).format('HH:mm'));
        this.ctaText = (this.order.status <= 1) ? this.translate.instant('card.close') : this.translate.instant('card.cancelled');
        this.classBtn = 'button--outline';
        this.classHeader += (this.order.status <= 1) ? ' card-site--cancel' : ' card-site--cancelled';
        return;
      case this.order.status === 0:
        this.ctaText = (this.utilsService.waitingFirestoreResponse$.value.find(orderWaiting => orderWaiting === this.order.id)) ? 'Enviando...' : this.translate.instant('card.start');
        this.flowStatus = Constants.FLOW_START;
        this.classBtn = 'button--outline';
        time = moment(milliseconds).diff(moment(this.order.dates.created_at));
        this.statusTime = (time >= 0) ? moment(time).format('mm:ss') : undefined;
        this.checkOrderState(milliseconds);
        return;
      case this.order.status === 1:
        this.ctaText = (this.utilsService.waitingFirestoreResponse$.value.find(orderWaiting => orderWaiting === this.order.id)) ? 'Enviando...' :  this.translate.instant('card.finish');
        this.flowStatus = Constants.FLOW_FINISH;
        this.classBtn = 'button--outline';
        time = moment(milliseconds).diff(moment(this.order.dates.timestamp_0));
        this.statusTime = (time >= 0) ? moment(time).format('mm:ss') : undefined;
        this.checkOrderState(milliseconds);
        return;
      case this.order.status === 2:
        this.ctaText = (this.utilsService.waitingFirestoreResponse$.value.find(orderWaiting => orderWaiting === this.order.id)) ? 'Enviando...' :  this.translate.instant('card.ready');
        this.startTime = moment(moment(this.order.dates.timestamp_2).diff(moment(this.order.dates.created_at))).format('mm');
        this.classBtn = 'button--outline';
        this.classHeader = 'card-site--finished';
        this.ordersStateService.checkDelayOrder(this.order.id, true);     
        return;
      case this.order.status >= 3:
        this.ctaText = this.translate.instant('card.delivered');
        this.classBtn = 'button--outline';
        this.statusTime = (moment(this.order.dates.timestamp_3 || this.order.dates.timestamp_4).format('HH:mm'));
        this.startTime = moment(moment(this.order.dates.timestamp_2).diff(moment(this.order.dates.created_at))).format('mm');
        this.dispatchTime = moment(moment(this.order.dates.timestamp_3 || this.order.dates.timestamp_4).diff(moment(this.order.dates.created_at))).format('mm');
        this.classHeader = 'card-site--finished';
        this.ordersStateService.checkDelayOrder(this.order.id, true);
        return;
      default:
        break;
    }
  }

  checkRiderStimation(milliseconds: number){
    let timeLeft = (this.order?.rider_arriving_at) ? moment(this.order?.rider_arriving_at).valueOf() - milliseconds : undefined;
    if(!timeLeft || this.order?.rider_arrived) return undefined;
    let minutes = (!!timeLeft && timeLeft > 0) ? moment.duration(timeLeft).minutes() : 1;
    return (minutes > 1) ? minutes : 1;
  }

  printOrder(){
    this.userService.printOrder(this.order.firebaseOrder);
  }

  private checkOrderState(milliseconds: number){
    const difference: number = (this.order?.finish_time_estimated - milliseconds)/1000;
    this.classHeader = 'card-site';

    switch (true) {
      case difference > Constants.WARNING_STATUS_TIME:
        this.classHeader += (this.order.status > 0) ? '--active-success' : '--disabled-success';
        break;
      case difference > 0 && difference < Constants.WARNING_STATUS_TIME:
        this.classHeader += (this.order.status > 0) ? '--active-warning' : '--disabled-warning';
        break;
      default:
        this.ordersStateService.checkDelayOrder(this.order.id);
        this.classHeader += (this.order.status > 0) ? '--active-alert' : '--disabled-alert';
        break;
    }
  }
}
