import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { BatchSet, batchItem } from '@models/batchSet.model';
import { StateCard, StrikedItem } from '@models/stateCard.model';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '@services';

import * as _ from 'lodash';

@Component({
  selector: 'app-order-high-demand-cook',
  templateUrl: './order-high-demand-cook.component.html',
  styleUrls: ['./order-high-demand-cook.component.scss'],
})
export class OrderHighDemandCookComponent {

  @ViewChild('orderCard') card: ElementRef;
  @Input() batchSet: BatchSet;
  @Input() batchActive: boolean;
  @Input() batchOpen: boolean;
  @Input() itemInProcess: batchItem | null;
  @Input() batchesSetIndex: number;
  @Input() updateCard: boolean;
  @Output() addBatchItem: EventEmitter<batchItem> = new EventEmitter();
  @Output() resetBatchItemInProccess: EventEmitter<any> = new EventEmitter();

  checksAnimation = false;

  dataCard: StateCard = {};

  itemClicked: number | null = null;

  constructor(
    public translate: TranslateService,
    public utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.orderState();

    if (this.batchActive) {
      this.utilsService.strikedItems$.subscribe((strikedItems: StrikedItem[]) => {

        this.batchSet.items.forEach(item => {
          // const strikedItem = strikedItems.find(strikedItem => strikedItem.item_id === item.item_id && strikedItem.item_modifiers.length === item.item_modifiers.length && strikedItem.item_modifiers.every((modifier) => item.item_modifiers.find(item_modifier => item_modifier.modifier_id === modifier)));
          const strikedItem = strikedItems.find(strikedItem => strikedItem.item_id === item.item_id && strikedItem.item_modifiers.length === item.item_modifiers.length && strikedItem.item_modifiers.every((modifier) => item.item_modifiers.find(item_modifier => item_modifier.modifier_name === modifier)));
          if (strikedItem) {
            item.item_is_striked = true;
          } else {
            item.item_is_striked = false;
          }
        }
        );
      })
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.checkOrderToRefresh(changes) || !!changes.updateCard) {
      this.orderState();
    }
  }

  addToCurrentBatch(item: batchItem) {
    this.addBatchItem.emit(item);
  }

  calculateUnits(batchSet: BatchSet) {
    const batchUnitEquivalence = batchSet.batch_size / (batchSet.batch_unit_equivalence as number);
    return Math.round(batchUnitEquivalence) + ' UNIDADES';
  }

  checkStrike(item: batchItem) {
    return item.item_is_striked;
  }


  checkAnimation(item: batchItem) {
    if (!this.batchOpen || this.batchesSetIndex !== 0 || !this.itemInProcess) return;
    let checkExistItemWithModifiers = null;
    if (this.itemInProcess) {
      checkExistItemWithModifiers = this.checkBatchInProcess(item);
    };

    if (checkExistItemWithModifiers) {
      this.resetBatchItemInProccess.emit();
    }

    return checkExistItemWithModifiers;
  }

  checkBatchInProcess(item: batchItem) {
    //buscar si el item en proceso tiene el mismo id y los mismos modifiers que el item actual
    const checkItem = this.itemInProcess.item_id === item.item_id;
    if (!checkItem) return false;
    const checkModifiers = this.itemInProcess.item_modifiers.length === item.item_modifiers.length;
    if (!checkModifiers) return false;
    // const checkModifiersValues = this.itemInProcess.item_modifiers.length === 0 || this.itemInProcess.item_modifiers.every((modifier, index) => modifier.modifier_id === item.item_modifiers[index].modifier_id);
    const checkModifiersValues = this.itemInProcess.item_modifiers.length === 0 || this.itemInProcess.item_modifiers.every((modifier, index) => modifier.modifier_name === item.item_modifiers[index].modifier_name);
    return checkModifiersValues;

  }

  checkOrderToRefresh(changes: SimpleChanges) {
    if (!changes.orderOriginal || !changes.orderOriginal.previousValue || !changes.orderOriginal.currentValue) return false;
    const result = _.pickBy(changes.orderOriginal.currentValue, (v, k) => !_.isEqual(changes.orderOriginal.previousValue[k], v));
    return !_.isEmpty(result);
  }

  setStrike(item: batchItem) {
    if (!this.batchOpen || this.batchesSetIndex !== 0) return;
    item.item_is_striked = !item.item_is_striked;
    // this.utilsService.updateStrikedItems({ item_id: item.item_id, item_modifiers: item.item_modifiers.map(modifier => modifier.modifier_id) });
    this.utilsService.updateStrikedItems({ item_id: item.item_id, item_modifiers: item.item_modifiers.map(modifier => {
      console.log("setStrike modifier", modifier);
      
      return modifier.modifier_name
    }) });
    return item.item_is_striked ? 'line-through' : '';
  }

  showBatchSet(batchSet) {
    console.log("batchSet clicked", batchSet);
  }

  trackByItems(item: any): string {
    return item
  }

  orderState() {
    this.dataCard.classHeader = '';
    this.dataCard.isGrid = true;
  }

  triggerAnimation(item: batchItem, index: number) {
    if (this.batchesSetIndex !== 1 || !this.batchOpen) return;
    this.checksAnimation = true;
    this.itemClicked = index;
    const sendTimeOut$ = setTimeout(() => {
      this.addToCurrentBatch(item);
      clearTimeout(sendTimeOut$);

    }, 350);
    const animationTimeOut$ = setTimeout(() => {
      this.checksAnimation = false;
      this.itemClicked = null;
      clearTimeout(animationTimeOut$);
    }, 850);
  }

}
