import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'parseUnitOfMesurement'
})
export class ParseUnitOfMesurementPipe implements PipeTransform {
  constructor(public translate: TranslateService) { }

  transform(value: string): string {
    return this.translate.instant(`mesurements_types.${value}`);
  }
}


