import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { fromEvent, Subscription, timer } from 'rxjs';
import { debounceTime, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'app-horizontal-scroll',
  templateUrl: './horizontal-scroll.component.html',
  styleUrls: ['./horizontal-scroll.component.scss'],
})
export class HorizontalScrollComponent implements OnInit {

  @ViewChild('horizontalScrollElem', { static: true })
  horizontalScrollElem: ElementRef | null = null

  @Input() dataColumns: {cardWidth:number, columns: number};

  enablePrev = false
  enableNext = false
  private scrollObserver: Subscription | null = null

  constructor() { }

  ngOnInit() {
    if (this.horizontalScrollElem) {
      const horizontalScrollElem = this.horizontalScrollElem;
      this.scrollObserver = fromEvent(
        horizontalScrollElem.nativeElement,
        'scroll',
      )
        .pipe(debounceTime(100), throttleTime(100))
        .subscribe(_ => {
          this.updateNavigationBtnStatus(horizontalScrollElem.nativeElement as HTMLElement)
        })
    }
  }

  ngOnChanges() {
    timer(100).subscribe(() => {
      if (this.horizontalScrollElem) {
        this.updateNavigationBtnStatus(this.horizontalScrollElem.nativeElement as HTMLElement)
      }
      if(this.dataColumns){
        this.setPagintation();
      }
    })
  }

  ngOnDestroy() {
    if (this.scrollObserver) {
      this.scrollObserver.unsubscribe();
      this.scrollObserver = null;
    }
  }

  private updateNavigationBtnStatus(elem: HTMLElement) {
    this.enablePrev = true;
    this.enableNext = true;

    if (elem.scrollLeft === 0) {
      this.enablePrev = false
    }
    if (elem.scrollWidth === elem.clientWidth + elem.scrollLeft) {
      this.enableNext = false
    }
  }

  goDirection(option: string) {
    if (this.horizontalScrollElem) {
      const clientWidth = (this.horizontalScrollElem.nativeElement.clientWidth * 0.9)
      const movement = (option == 'next') 
          ? this.horizontalScrollElem.nativeElement.scrollLeft + clientWidth
          : this.horizontalScrollElem.nativeElement.scrollLeft - clientWidth;
      this.horizontalScrollElem.nativeElement.scrollTo({
        left: movement,
        behavior: 'smooth',
      })
    }
  }

  setPagintation(){
    if (this.horizontalScrollElem) {
      const clientWidth = this.horizontalScrollElem.nativeElement.clientWidth;

    }
  }
}
