import { Component, Output, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { UserData } from '@models/userData.model';

import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { AppState } from '../../../core/app.reducer';
import { FirebaseStats, StatsData } from '@models/stats.model';
import { OrderCardData } from '@models/orderCardData.model';

import * as moment from 'moment';
import { StationBrandsService, UserService, UtilsService } from '@services';
import { ConfigData } from '@models/config.model';
import { FirebasePeople, PeopleData } from '@models/people.model';
import { FirebaseSite, SitesData } from '@models/sites.model';
import * as stationRestaurantsActions from '../../../features/station-brands/store/station-brands.actions';
import { BrandModel } from '@models/brand.model';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})


export class MenuComponent implements OnDestroy, OnInit {
  @Input() displayType: string;
  @Input() activeType: string;
  @Input() config: ConfigData;
  @Input() stats$: Observable<StatsData>;
  @Input() focusActive: boolean;
  @Output() menu: EventEmitter<any> = new EventEmitter();
  @Output() calculator: EventEmitter<any> = new EventEmitter();
  @Output() display: EventEmitter<any> = new EventEmitter();
  @Output() moreOrders: EventEmitter<any> = new EventEmitter();
  @Output() allOrders: EventEmitter<any> = new EventEmitter();

  people$: Observable<PeopleData> = this.store.select(state => state.people);
  peopleSubscription$: Subscription;
  peopleSubject$: BehaviorSubject<FirebasePeople> = new BehaviorSubject(null);

  sites$: Observable<SitesData> = this.store.select(state => state.sites);
  sitesSubscription$: Subscription;
  sitesSubject$: BehaviorSubject<FirebaseSite> = new BehaviorSubject(null);

  showCalculator: boolean | null = null;

  stationBrands$: Observable<BrandModel[]> = this.store.select(state => state.stationBrands);
  stationBrandsSubscription$: Subscription;
  stationBrandsSubject$: BehaviorSubject<BrandModel[]> = new BehaviorSubject(null);

  user$: Observable<UserData> = this.store.select(state => state.user);
  userSubscription$: Subscription;
  userSubject$: BehaviorSubject<UserData> = new BehaviorSubject(null);

  storesSubscription$: Subscription;
  storesSubject$: BehaviorSubject<any> = new BehaviorSubject(null);

  timeToShowSubject$: BehaviorSubject<string> = new BehaviorSubject('0');
  alertOrdersSubject$: BehaviorSubject<number> = new BehaviorSubject(0);
  activeOrdersSubject$: BehaviorSubject<number> = new BehaviorSubject(0);

  showAllOrders: boolean = false;

  menuExpanded = false;
  displayGrid = true;
  displayDashboard = false;
  accountPages = [
    {
      title: 'Log In',
      url: '/auth/login',
      ionicIcon: 'log-in-outline'
    },
    {
      title: 'Sign Up',
      url: '/auth/signup',
      ionicIcon: 'person-add-outline'
    }
  ];

  brandClosed: boolean = false;

  highDemandSite: string = 'viz';

  constructor(

    private stationBrandsService: StationBrandsService, 
    private store: Store<AppState>, 
    private userService: UserService,
    private utilsService: UtilsService
    ) {
    this.userSubscription$ = this.user$.subscribe( user =>{
      this.userSubject$.next(user);
      let stationBrands: BrandModel[] = [];

      if(user && user.stationRestaurants && user.stationRestaurants.length > 0){
        user.stationRestaurants.forEach( restaurant => {
          if(!restaurant.is_active) return;
          let brandData: BrandModel = {
            external_id: restaurant.external_id,
            name: restaurant.name,
            is_active: restaurant.is_active,
            is_open: false,
            created_at: restaurant.created_at
          }
          stationBrands.push(brandData);
        })
      }
      this.store.dispatch(stationRestaurantsActions.fetchStationBrands({ brands: stationBrands }));
    });

    this.peopleSubscription$ = this.people$.subscribe( people =>{
      this.peopleSubject$.next(people.responsibleSelected);
    });

    this.sitesSubscription$ = this.sites$.subscribe( sites =>{
      this.sitesSubject$.next(sites.siteSelected);
    });

    this.stationBrandsSubscription$ = this.stationBrands$.subscribe(stationRestaurants => {
      this.brandClosed = stationRestaurants.some(restaurant => !restaurant.is_open);
      this.stationBrandsSubject$.next(stationRestaurants);
    });
  }

  ngOnInit() {
    const delayPolling$ = timer(500).subscribe({

      next: () => {
        this.stationBrandsService.pollingStationBrands();
      },
      complete: () => {
        delayPolling$.unsubscribe();
      }
    })
    // this.stationBrandsService.pollingStationBrands()
  }
  //reduce the size of a string to 3 characters
  reduceString(str: string){
    return str.substring(0,3);
  }

  ngOnDestroy(){
    if(this.userSubscription$) this.userSubscription$.unsubscribe();
    if(this.peopleSubscription$) this.peopleSubscription$.unsubscribe();
    if(this.sitesSubscription$) this.sitesSubscription$.unsubscribe();
    if(this.stationBrandsSubscription$) this.stationBrandsSubscription$.unsubscribe();
  }

  changePeople() {
    this.utilsService.showPeopleSelectModal();
    if(this.menuExpanded) this.openMenu();
  }

  changeSite() {
    this.utilsService.showSitesModal();
    if(this.menuExpanded) this.openMenu();
  }

  openMenu() {
    this.menuExpanded = !this.menuExpanded;
 		this.menu.emit(this.menuExpanded);
  }

  classIcon(){
    switch (true) {
      case this.activeType === 'site':
        return this.displayDashboard ? 'icon-display icon--big' : 'icon-stats';
      case this.activeType !== 'site':
        return this.displayGrid ? 'icon-list icon--big' : 'icon-display';
    }
  }

  toggleDisplay() {

      this.displayGrid = !this.displayGrid;
      const displayType = (this.displayGrid) ? 'grid' : 'list';
      this.display.emit(displayType);

  }

  toggleCalculator() {
    if(this.showCalculator === null) {
      this.showCalculator = true;
    } else {
      this.showCalculator = !this.showCalculator;
    }

    this.calculator.emit(this.showCalculator);
  }

  showDashboard(){
    if(this.activeType !== 'site') {return;}
    this.displayDashboard = !this.displayDashboard;
    const displayType = (this.displayDashboard) ? 'dashboard' : 'list';
    this.display.emit(displayType);
  }

  checkAlertOrders(orders: OrderCardData[]){
    const ordersAlert = orders.filter(order => order.time_state === 3);
    return ordersAlert.length;
  }

  checkActiveOrders(orders: OrderCardData[]){
    const ordersActive = orders.filter(order => order.status < 3);
    return ordersActive.length;
  }

  signOut(){
    this.userService.signOut();
  }

  checkTimeToShow(firebaseStats: FirebaseStats){
    const user: UserData = this.userSubject$.value;
    if(!firebaseStats || !user) {return '0';}
    let totalTime = 0;
    if(!!firebaseStats?.totals?.stc_time) totalTime += firebaseStats?.totals?.stc_time;
    if(!!firebaseStats?.totals?.prep_time) totalTime += firebaseStats?.totals?.prep_time;
    const time = moment.duration(totalTime/(firebaseStats?.totals?.num_orders || 1));
    const settedTime = ((time.hours()*60 + time.minutes()) + time.seconds()/60).toFixed(0);
    return settedTime;
  }

  resetApp(){
    this.userService.reloadApp();
  }

  updateShowAllOrders(){
    this.showAllOrders = !this.showAllOrders;
    this.allOrders.emit(this.showAllOrders);
  }
}
